import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import contactinfo from "../../../data/contactinfo.json";
import axios from "axios";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";
import { useLocation } from "react-router-dom";
const Faqs = () => {
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    axios.get(`${BASE_URL}/admin/get-faqs`).then((res) => {
      setFlag(true);
      // console.log(res?.data?.result);
      setData(res.data.result);
    });
  }, []);
  const returnmap=()=>{
    return (
      window.open(
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.679615767014!2d100.55757287456042!3d13.858260094820896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29cc59ba01639%3A0x911c039d115b1c76!2zODQvMiDguIvguK3guKIg4LiH4Liy4Lih4Lin4LiH4Lio4LmM4Lin4Liy4LiZIDQ3IOC5geC4ouC4gSA2LTExIOC5geC4guC4p-C4h-C4l-C4uOC5iOC4h-C4quC4reC4h-C4q-C5ieC4reC4hyDguYDguILguJXguKvguKXguLHguIHguKrguLXguYgg4LiB4Lij4Li44LiH4LmA4LiX4Lie4Lih4Lir4Liy4LiZ4LiE4LijIDEwMjEw!5e0!3m2!1sth!2sth!4v1728041733676!5m2!1sth!2sth",
        "_blank"
      )
    )
}
  return (
    <div className="">
      <div className="container">
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">{t("FAQ")}</h5>
          <h2 className="title">{t("FAQ")}</h2>
        </div>
        <div className="row">
          <div className="col-lg-8 mb-lg-30">
            {data?.length === 0 && flag ? (
              <h5>No Data</h5>
            ) : data?.length === 0 && flag === false ? (
              <h5>Loading ...</h5>
            ) : (
              <Accordion defaultActiveKey="0" className="with-gap">
                {Array.isArray(data) &&
                  data?.map((res, key) => (
                    <Accordion.Item eventKey={key + 1} key={key}>
                      <Accordion.Header>{t(res.title)}</Accordion.Header>
                      <Accordion.Body className="collapseparent">
                        {res.content.split("\n").map((line, index) => (
                          <p key={index}>{t(line)}</p>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            )}
          </div>
          <div className="col-lg-4 infographics-5">
            {contactinfo.slice(0, 2).map((item, i) => (
              <div key={i} className="acr-infographic-item">
                <i className={"flaticon-" + item.icon + ""} />
                <div className="acr-infographic-item-body">
                  <h5>{t(item.title)}</h5>
                  <p>{t(item.desfaq)}</p>
                  {item?.title == "Find Us" ? (
                     <button
                     className="btn-custom secondary btn-sm"
                     onClick={returnmap}
                   >
                     {item.btntext}
                   </button>
                  ) : (
                    <Link
                      to={item.btnurl}
                      className="btn-custom secondary btn-sm"
                    >
                      <button className="btn-custom secondary btn-sm">
                        {t(item.btntext)}
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
