import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Nav, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Locationtab from "./Locationtab";
// import { statusList } from "../../../data/common";
import convertToBase64 from "../../../helper/convert";
import { Button, Select } from "antd";
import bathoom from "../../../bathoom.png";
import bedroom from "../../../bedroom.png";
import Swal from "sweetalert2";
import { Container, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";
import ConditionModal from "../../layouts/Condition_Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ConfirmModal from "./ConfirmModal";
const { Option } = Select;
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "flex",
  flexDirection: "column",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  height: "75%",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const FloorContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const floor = {
  display: "flex",
  flexDirection: "column",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const floorInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  height: "75%",
};

const floorimg = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Content() {
  const toast = useToast();
  const navigate = useNavigate();
  const [disbtn, setDisBtn] = useState(false);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [open, setOpen] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  const [typeList, setTypeList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [nearTypeList, setNearTypeList] = useState([]);
  const [tabKey, setTabKey] = useState("tab1");
  const [envormentalValue, setEnvormentalValue] = useState("");
  const [title, setTitle] = useState(null);
  const [slug, setSlug] = useState(null);
  const [metaTag, setMetaTag] = useState("");
  const [keyWords, setKeyWords] = useState("");
  useEffect(() => {
    axios.get(`${BASE_URL}/admin/get-categories`).then((res) => {
      setTypeList(res.data.result);
    });
    axios.get(`${BASE_URL}/admin/get-currencies`).then((res) => {
      setCurrencyList(res.data.result);
    });
    axios.get(`${BASE_URL}/admin/get-nearbytypes`).then((res) => {
      setNearTypeList(res.data.result);
    });
  }, []);

  // Error
  const [error, setError] = useState(undefined);
  const [fieldError, setFieldError] = useState(undefined);
  const [minFileError, setMinFileError] = useState(undefined);
  const [maxFileError, setMaxFileError] = useState(undefined);

  //  Basic Information
  const [description, setDescription] = useState("");
  const [MonthlyMortage, setMonthlyMortage] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState(0);
  const [period, setPeriod] = useState("");
  const [space, setSpace] = useState(0);
  const [land, setLand] = useState(0);
  const [video, setVideo] = useState(null);
  const [videofile, setVideofile] = useState(null);
  const [rental, setRental] = useState(false);
  const [furnished, setFurnished] = useState("");
  const [furnishedTypes, setFurnishedTypes] = useState("");
  const [maintenanceFee, setMaintenanceFee] = useState("");
  const [roomNo, setroomNo] = useState("");
  const [RoomFloor, setRoomFloor] = useState("");

  //  Gallery
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();

  //Agent Picture
  const [agentthumbnail, setagentThumbnail] = useState();
  const [agentthumbnailUrl, setagentThumbnailUrl] = useState();
  const [agentName, setAgentName] = useState();
  const [agentLastName, setAgentLastName] = useState();
  const [agentTel, setAgentTel] = useState();
  const [countryCode, setCountryCode] = useState("th");
  const [agentwhatapp, setAgentWhatsApp] = useState();
  const [agentProvince, setAgentProvince] = useState();
  const [agentCountry, setAgentCountry] = useState();
  const [laundryroom, setLaundryroom] = useState("");
  // Property Gallery
  const [files, setFiles] = useState([]);
  const {
    getRootProps: getPropertyRootProps,
    getInputProps: getPropertyInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleDeleteImage = (name) => {
    const updatedFiles = files.filter((file) => file.name !== name);
    setFiles(updatedFiles);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          alt="ThumbImage"
          style={floorimg}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <button
        style={{ backgroundColor: "#ADD8E6", height: "25%" }}
        onClick={() => handleDeleteImage(file.name)}
      >
        Delete
      </button>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  // Floor Images
  const [floorImages, setFloorImages] = useState([]);
  const { getRootProps: getFloorRootProps, getInputProps: getFloorInputProps } =
    useDropzone({
      accept: { "image/*": [] },
      onDrop: (acceptedFiles) => {
        setFloorImages(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const handleDeleteFloorImage = (name) => {
    const updatedFiles = floorImages.filter((file) => file.name !== name);
    setFloorImages(updatedFiles);
  };

  const floorthumbs = floorImages.map((file) => (
    <div style={floor} key={file.name}>
      <div style={floorInner}>
        <img
          src={file.preview}
          alt="ThumbImage"
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <button
        style={{ backgroundColor: "#ADD8E6", height: "25%" }}
        onClick={() => handleDeleteFloorImage(file.name)}
      >
        Delete
      </button>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      floorImages.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [floorImages]);

  // property thumbnail
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleThumbnailChange = async (e) => {
    const files = Array.from(e.target.files);
    const base64Promises = files.map((file) => convertToBase64(file));
    const base64Array = await Promise.all(base64Promises);

    setThumbnailUrl(base64Array);
    setThumbnail(files);
  };

  const handleDeleteThumbnail = (index) => {
    const updatedThumbnails = thumbnail.filter((_, i) => i !== index);
    const updatedThumbnailUrl = thumbnailUrl.filter((_, i) => i !== index);
    setThumbnail(updatedThumbnails);
    setThumbnailUrl(updatedThumbnailUrl);
  };

  //  Location
  const [location, setLocation] = useState({
    address: "",
    country: "",
    city: "",
    provice: "",
    Tumbol: "",
    zipcode: "",
    embedCode: "",
    lat: "13.736717",
    long: "100.523186",
  });
  console.log(location);
  const locationData = (data) => {
    // console.log(data);
    setLocation(data);
  };

  //  Feature
  // const [features, setFeatures] = useState([]);
  // const featuresData = (id) => {
  //   if (features.indexOf(id) !== -1) {
  //     features.splice(features.indexOf(id), 1);
  //   } else {
  //     setFeatures([...features, id]);
  //   }
  // };

  //  Details
  const [id, setId] = useState(0);
  const [beds, setBeds] = useState(0);
  const [baths, setBaths] = useState(0);
  const [condition, setCondition] = useState("");
  const [built, setBuilt] = useState("");
  const [neighbor, setNeighbor] = useState("");
  const [living, setLiving] = useState("");
  const [dining, setDining] = useState("");
  const [story, setStory] = useState(0);
  const [parking, setParking] = useState("");
  const [lotsize, setLotsize] = useState("");
  const [bArea, setbArea] = useState("");
  const [view, setView] = useState("");
  const [nears, setNears] = useState({ rows: [] });
  const [nearId, setNearId] = useState([]);

  const [meetingroom, setMeetingroom] = useState("");
  const [gameroom, setGameroom] = useState("");
  const [maid, setMaid] = useState("");
  const [jacuzzi, setJacuzzi] = useState("");
  const [tenniscourt, settenniscourt] = useState("");
  const [jacuzzi1, setJacuzzi1] = useState("");
  const [sauna1, setsauna1] = useState("");
  const [sauna, setSauna] = useState("");
  const [lineid, setlineid] = useState();
  const [wechatId, setwechatId] = useState();
  const [guest, setGuest] = useState("");
  const [Aircondition, setAircondition] = useState("");
  const [solarPower, setsolarPower] = useState("");
  const [seaview, setseaview] = useState("");
  const [Electricity, setElectricity] = useState("");
  const [Elevator, setElevator] = useState("");
  const [paidwater, setpaidwater] = useState("");
  const [penthouse, setpenthouse] = useState("");
  const [currencymonthly, setCurrencymonthly] = useState("");
  const [currencyMin, setCurrencyMin] = useState("");
  const [currencyMax, setcurrencyMax] = useState("");
  const [currencyMaintainance, setcurrencyMaintainance] = useState("");
  const { t } = useTranslation();

  const addRow = () => {
    if (
      nears.rows.length === 0 ||
      nears.rows[nears.rows.length - 1].name !== ""
    ) {
      const newRow = {
        neartype: nearTypeList[0]?._id,
        name: "",
        distance: "",
        isEdit: false,
        key: "",
      };
      setNears({
        rows: [...nears.rows, newRow], // Append the new row to the existing rows.
      });
    } else {
      // If the last row is empty, prompt the user to fill it.
      alert("Please fill out the last row before adding a new one.");
    }
  };

  const enableEdit = (e, idx) => {
    e.preventDefault();
    if (nears.rows[idx].name != "") {
      const multy = [...nears.rows];

      if (e.target.innerHTML === "Save") {
        e.target.innerHTML = "Edit";
        multy[idx].isEdit = false;
      } else {
        e.target.innerHTML = "Save";
        multy[idx].isEdit = false;
      }

      if (e.target.innerHTML === "Edit") {
        // e.target.innerHTML = "Edit";
        // multy[idx].isEdit = false;

        multy[idx].isEdit = true;
        axios
          .post(`${BASE_URL}/admin/near/create`, multy[idx])
          .then((res) => {
            multy[idx].key = res.data.result._id;
            setNearId([...nearId, res.data.result._id]);
          })
          .catch((err) => console.log(err));
      } else {
        // multy[idx].isEdit = true;
        console.log("faild");
      }

      setNears({
        rows: [...multy],
      });

      // if (e.target.innerHTML === "Save") {
      //   e.target.innerHTML = "Edit";
      //   multy[idx].isEdit = false;
      // } else {
      //   e.target.innerHTML = "Save";
      //   multy[idx].isEdit = true;
      //   axios
      //     .post(
      //       `${BASE_URL}/admin/near/create`,
      //       multy[idx]
      //     )

      //     .then((res) => {
      //       multy[idx].key = res.data.result._id;
      //       setNearId([...nearId, res.data.result._id]);
      //     })
      //     .catch((err) => console.log(err));
      // }

      // setNears({
      //   rows: [...multy],
      // });
    }
  };
  const handlefile = (event) => {
    // console.log(event, "helo");
    const file = event.target.files[0];
    // console.log(file, "file");
    if (file) {
      setVideofile(file);
    }
  };
  const removeRow = (e, idx) => {
    e.preventDefault();
    const selectRow = [...nears.rows];

    if (selectRow[idx].isEdit) {
      axios
        .delete(`${BASE_URL}/admin/delete/near/${selectRow[idx].key}`)
        .then((res) => {
          nearId.splice(nearId.indexOf(res.data.result), 1);
        })
        .catch((err) => console.log(err));
    }
    selectRow.splice(idx, 1);
    setNears({
      rows: [...selectRow],
    });
  };

  const [bbqarea, setBBQArea] = useState("");
  const [descriptions, setdescriptions] = useState("");
  const [partyhall, setPartyHall] = useState("");
  const [communityHall, setCommunityHall] = useState("");
  const [swimmingpoolguest, setSwimmingpoolguest] = useState("");
  const [guestgym, setGymguest] = useState("");
  const [guestParking, setGuestParking] = useState("");
  const [minimart, setMinimart] = useState("");
  const [libraryguest, setLibraryguest] = useState("");
  const [park, setPark] = useState("");
  const [rentalPrice, setRentalPrice] = useState();
  const [listingtype, setlistingtype] = useState();
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [modal, setModal] = useState("");
  const [emptyfield, setemptyField] = useState([]);
  const [playGround, setPlayGround] = useState("");
  const [toggleLineId, setToggleLineId] = useState(true);
  const [toggleWechatId, setToggleWechatId] = useState(true);
  const [toggleAgentWhatsApp, setToggleAgentWhatsApp] = useState(true);
  const [toggleTelephone, setTelephone] = useState(true);
  const [AgentService, setAgentService] = useState("");
  const [heater, setheater] = useState("");
  const [Kitchens, setKitchens] = useState("");
  //  Validation
  const validate = () => {
    if (!user) {
      Swal.fire({
        // text: "Good job!",
        title: "You need to login first!",
      });
      return false;
    }

    const ErrFields = [
      { name: description, text: "Description", tab: "tab1" },
      { name: name, text: "Name", tab: "tab1" },
      { name: status, text: "Status", tab: "tab1" },
      { name: listingtype, text: "Listing Type", tab: "tab1" },
      { name: type, text: "Type", tab: "tab1" },
      // { name: price, text: "Sale Price" },
      // { name: space, text: "Building Area (SQM)", tab: "tab1" },
      // { name: bbqarea, text: "BBQ Area", tab: "tab1" },
      // { name: MonthlyMortage, text: "Monthly Mortage" },
      // { name: land, text: "Land Area", tab: "tab1" },
      { name: minPrice, text: "Min Price", tab: "tab1" },
      { name: maxPrice, text: "Max Price", tab: "tab1" },
      // { name: "Condition Check", text: "Condition", tab: "tab1" },
      { name: maintenanceFee, text: "Maintenance Fee", tab: "tab1" },
      // { name: rentalPrice, text: "Rental Price" },
      // { name: "video", text: "Video url or Video", tab: "tab1" },
      // { name: videofile, text: "Video",tab: "tab1" },

      { name: thumbnail, text: "Thumbnail", tab: "tab2" },
      { name: files, text: "Gallery", tab: "tab2" },
      // { name: floorImages, text: "Floor Images", tab: "tab8" },
      // { name: location.address, text: "Full Address", tab: "tab3" },
      // { name: location.country, text: "Country", tab: "tab3" },
      // { name: location.Tumbol, text: "Tumbol", tab: "tab3" },
      // { name: location.city, text: "City", tab: "tab3" }, // {name:location.provice,text:"Provice",tab:"tab3"},
      // { name: location.zipcode, text: "Zip Code", tab: "tab3" },
      { name: location.embedCode, text: "Embed Link", tab: "tab3" },
      // { name: bbqarea, text: "BBQ Area", tab: "tab4" },
      // { name: partyhall, text: "Party Hall", tab: "tab4" },
      // { name: communityHall, text: "Community Hall", tab: "tab4" },
      // { name: swimmingpoolguest, text: "Swimming Pool", tab: "tab4" },
      // { name: guestgym, text: "Gym", tab: "tab4" },
      // { name: guestParking, text: "Guest Parking", tab: "tab4" },
      // { name: minimart, text: "Mini Mart", tab: "tab4" },
      // { name: libraryguest, text: "Library", tab: "tab4" },
      // { name: park, text: "Children Playground", tab: "tab4" },
      // { name: tenniscourt, text: "Tennis Court", tab: "tab4" },
      // { name: jacuzzi1, text: "Jacuzzi", tab: "tab4" },
      // { name: sauna1, text: "Sauna", tab: "tab4" },
      // { name: Aircondition, text: "Fans", tab: "tab4" },
      // { name: solarPower, text: "solarPower", tab: "tab4" },
      // { name: seaview, text: "seaview", tab: "tab4" },
      // { name: hotWater, text: "hotWater", tab: "tab4" },
      // { name: oceanFront, text: "oceanFront", tab: "tab4" },
      // { name: outdoorBath, text: "outdoorBath", tab: "tab4" },

      // { name: playGround, text: "Play Ground", tab: "tab4" },
      // { name: id, text: "ID", tab: "tab5" },
      // { name: beds, text: "Beds", tab: "tab5" },
      // { name: baths, text: "Baths", tab: "tab5" },
      // { name: story, text: "Building Floor", tab: "tab5" },
      // { name: lotsize, text: "Lot Size", tab: "tab5" },
      // { name: bArea, text: "Year Built", tab: "tab5" },
      // { name: neighbor, text: "Neighborhood", tab: "tab5" },
      // { name: living, text: "Living Room", tab: "tab5" },
      // { name: dining, text: "Dining Room", tab: "tab5" },
      // { name: laundryroom, text: "Laundry Room", tab: "tab5" },
      // { name: meetingroom, text: "Meeting room", tab: "tab5" },
      // { name: gameroom, text: "Game Room", tab: "tab5" },
      // { name: jacuzzi, text: "Jacuzzi", tab: "tab5" },
      // { name: sauna, text: "Sauna", tab: "tab5" },
      // { name: maid, text: "Maid Room", tab: "tab5" },
      // { name: guest, text: "Guest room", tab: "tab5" },
      // { name: guestParking, text: "Parking", tab: "tab5" },
      // { name: built, text: "Year Built", tab: "tab5" },
      // { name: view, text: "View", tab: "tab5" },
      // { name: condition, text: "Condition", tab: "tab5" },
      // { name: firstCheckbox, text: "Please fill the check box", tab: "tab5" },

      // { name: agentthumbnail, text: "Agent Picture", tab: "tab6" },
      // { name: agentName, text: "Agent Name", tab: "tab6" },
      // { name: agentLastName, text: "Agent Last Name", tab: "tab6" },
      // { name: agentTel, text: "Agent Tel", tab: "tab6" },
      // { name: agentProvince, text: "Agent Province", tab: "tab6" },
      // { name: agentCountry, text: "Country Province", tab: "tab6" },
      // { name: AgentService, text: "Agent Service", tab: "tab6" },
      // { name: lineid, text: "Line Id", tab: "tab6" },
      // { name: wechatId, text: "We Chat Id", tab: "tab6" },
      // { name: agentwhatapp, text: "Agent Whatapp", tab: "tab6" },
      // { name: title, text: "Title", tab: "tab7" },
      // { name: slug, text: "Slug", tab: "tab7" },
      // { name: metaTag, text: "Meta Tag", tab: "tab7" },
      // { name: keyWords, text: "Key Work", tab: "tab7" },

      // { name: descriptions, text: "Descriptions", tab: "tab7" },
      // { name: secondCheckbox, text: "Please fill the check box", tab: "tab7" },
    ];

    for (let i = 0; i < ErrFields.length; i++) {
      // console.log(ErrFields[i].name, status);
      if (ErrFields[i].name === status && status === "") {
        toast({
          title: "Error",
          description: "Insert Property " + ErrFields[i].text,
          status: "error",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        setTabKey("tab1");
        return false; // Break the loop and return false
      }
      // if (ErrFields[i].text === "Condition") {

      // }
      // if (ErrFields[i].text == "Video url or Video") {
      //   if (!video && !videofile?.name) {
      //     toast({
      //       title: "Error",
      //       description: "Insert Property Video url or Video",
      //       status: "error",
      //       duration: 2000,
      //       variant: "left-accent",
      //       position: "top-right",
      //       isClosable: true,
      //     });
      //     setTabKey("tab1");
      //     return false;
      //   }
      // }
      if (ErrFields[i].text == "Gallery") {
        if (files.length === 0) {
          toast({
            title: "Error",
            description: "Insert Property Gallery",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setTabKey("tab2");
          return false;
        }
        if (files.length > 15) {
          toast({
            title: "Error",
            description: "You can upload only 15 pictures",
            status: "warning",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setTabKey("tab2");
          return false;
        }
      }
      // if (ErrFields[i].text == "Floor Images") {
      //   if (floorImages.length === 0) {
      //     toast({
      //       title: "Error",
      //       description: "Insert Floor Images",
      //       status: "error",
      //       duration: 2000,
      //       variant: "left-accent",
      //       position: "top-right",
      //       isClosable: true,
      //     });
      //     setTabKey("tab8");
      //     return false;
      //   }
      //   if (floorImages.length > 15) {
      //     toast({
      //       title: "Error",
      //       description: "You can upload only 15 pictures",
      //       status: "warning",
      //       duration: 2000,
      //       variant: "left-accent",
      //       position: "top-right",
      //       isClosable: true,
      //     });
      //     setTabKey("tab8");
      //     return false;
      //   }
      // }
      if (ErrFields[i].text == "Agent Picture") {
        if (files.length === 0) {
          toast({
            title: "Error",
            description: "Insert Property Agent Picture",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setTabKey("tab6");
          return false;
        }
      }
      if (ErrFields[i].text == "Maintenance Fee") {
        if (!maintenanceFee) {
          toast({
            title: "Error",
            description: "Insert Property Maintenance Fee",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setTabKey("tab1");
          return false;
        }
      }
      if (!ErrFields[i].name) {
        toast({
          title: "Error",
          description: "Insert Property " + ErrFields[i].text,
          status: "error",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        setTabKey(ErrFields[i]?.tab ? ErrFields[i]?.tab : "tab1");
        return false; // Break the loop and return false
      }
    }
    if (listingtype === "Rent" && !rentalPrice) {
      toast({
        title: "Error",
        description: "Insert Rental Price",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false; // Break the loop and return false
    }
    if (listingtype !== "Rent" && !price) {
      toast({
        title: "Error",
        description: "Insert Property Price or Monthly mortage",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false; // Break the loop and return false
    }
    if (
      listingtype === "Rent" &&
      furnished === "Yes" &&
      furnishedTypes.length === 0
    ) {
      toast({
        title: "Error",
        description: "Please select at least one furnished type",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false; // Break the loop and return false
    }
    console.log("maxPrice:", maxPrice, "minPrice:", minPrice);

    const maxPriceNumber = parseFloat(maxPrice);
    const minPriceNumber = parseFloat(minPrice);

    // Check for NaN values after conversion
    if (isNaN(maxPriceNumber) || isNaN(minPriceNumber)) {
      console.error("Invalid input: maxPrice or minPrice is not a number");
      toast({
        title: "Error",
        description: "Invalid input: maxPrice or minPrice is not a number",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }

    // console.log("Converted maxPriceNumber:", maxPriceNumber, "minPriceNumber:", minPriceNumber);

    if (maxPriceNumber <= minPriceNumber) {
      // console.log(maxPriceNumber <= minPriceNumber);
      toast({
        title: "Error",
        description: "Range is incorrect",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }

    return true;
  };

  useEffect(() => {
    // console.log(location);
  }, [location]);
  //  Submit

  const submitData = (e) => {
    if (e) {
      e.preventDefault(); // Check if the event is present before preventing default behavior
    }
    setemptyField([]);
    setModal(false);
    if (!validate()) {
      return;
    }
    setDisBtn(true);
    if (user === null) {
      toast({
        title: "Error",
        description: "You need to login first.",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    } else {
      // const stringValue = status.join(", ");
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("status", status);
      formData.append("listingType", listingtype);
      formData.append("type", type ? type : typeList[0].name);
      formData.append("currency", currency ? currency : currencyList[0].symbol);
      formData.append("price", price);
      formData.append("period", period ? period : "Monthly");
      formData.append("space", space);
      formData.append("land", land);
      formData.append("video", video ? video : "");
      formData.append("videofile", videofile ? videofile : "");
      formData.append("mortage", MonthlyMortage);
      formData.append("roomfloor", RoomFloor || "");
      formData.append("roomNo", roomNo || "");
      formData.append(
        "mortagecurrency",
        currencymonthly ? currencymonthly : currencyList[0].symbol
      );
      formData.append("minRange", minPrice);
      formData.append(
        "minRangecurrency",
        currencyMin ? currencyMin : currencyList[0].symbol
      );
      formData.append("maxRange", maxPrice);
      formData.append(
        "maxRangecurrency",
        currencyMin ? currencyMin : currencyList[0].symbol
      );
      thumbnail.forEach((file, inx) => formData.append("thumbnail", file));
      files.map((file, inx) => formData.append("picture", file));
      floorImages.map((file, inx) => formData.append("Floorimage", file));

      formData.append("latitude", location.lat);
      formData.append("longitude", location.long);
      formData.append("embedCode", location.embedCode);
      formData.append("address", location.address);
      formData.append("country", location.country);
      formData.append("city", location.city);
      formData.append("provice", location.province);
      formData.append("zipcode", location.zipcode);
      formData.append("tumbol", location.Tumbol);
      //SEO
      formData.append("title", title);
      formData.append("slug", slug);
      formData.append("metaTag", metaTag);
      formData.append("keyWords", keyWords);
      formData.append("seodes", descriptions);

      // formData.append("features", features);

      formData.append("id", id);
      formData.append("rentalPrice", rentalPrice);
      formData.append("beds", beds);
      formData.append("bathrooms", baths);
      formData.append("condition", condition);
      formData.append("built", built);
      formData.append("neighbor", neighbor);
      formData.append("living", living);
      formData.append("dining", dining);
      formData.append("story", story);
      formData.append("parking", parking);
      formData.append("lotsize", lotsize);
      formData.append("view", view);
      formData.append("near", nearId);
      // formData.append('gyms', gym);
      // formData.append('library', library);
      formData.append("laundryRoom", laundryroom);
      formData.append("gameRoom", gameroom);
      formData.append("sauna", sauna);
      formData.append("maidRoom", maid);
      formData.append("guestRoom", guest);
      formData.append("meetingRoom", meetingroom);
      // formData.append("jacuzzi", jacuzzi);
      formData.append("tenniscourt", tenniscourt);
      formData.append("jacuzzi", jacuzzi1);
      // formData.append("landSize", bArea);
      // formData.append("sauna1", sauna1);
      formData.append("Fans", Aircondition);
      formData.append("solarPower", solarPower);
      formData.append("seaview", seaview);
      formData.append("hotWater", paidwater);
      formData.append("oceanFront", Electricity);
      formData.append("outdoorBath", Elevator);
      formData.append("penthouse", penthouse);
      formData.append("Kitchens", Kitchens);
      formData.append("heater", heater);
      // formData.append('swimmingPool', swimmingpool);
      formData.append("envormentValue", envormentalValue);
      formData.append("category", type ? type : typeList[0].name);
      // formData.append("buy");

      formData.append("BBQArea", bbqarea);
      formData.append("PartyHall", partyhall);
      formData.append("CommunityHall", communityHall);
      formData.append("SwimmingPool", swimmingpoolguest);
      formData.append("Gym", guestgym);
      formData.append("GuestParking", guestParking);
      formData.append("MiniMart", minimart);
      formData.append("Library", libraryguest);
      formData.append("Park", park);
      formData.append("playGround", playGround);

      formData.append("authorId", user?._id);

      //Agent formdata
      formData.append("agentthumbnail", agentthumbnail);
      formData.append("agentLastName", agentLastName);
      formData.append("agentName", agentName);
      formData.append("agentTel", agentTel);
      formData.append("agentServices", AgentService);
      formData.append("countryCode", countryCode);
      formData.append("agentwhatapp", agentwhatapp);
      formData.append("agentProvince", agentProvince);
      formData.append("agentCountry", agentCountry);
      formData.append("Line_Id", lineid);
      formData.append("WeChatID", wechatId);
      formData.append("maintainanceFee", maintenanceFee);
      formData.append(
        "maintainanceFeecurrency",
        currencyMaintainance ? currencyMaintainance : currencyList[0].symbol
      );
      formData.append("isFurnished", furnished);
      formData.append("FurnishedProperties", furnishedTypes);
      setDisBtn(() => true);

      axios
        .post(`${BASE_URL}/submitlisting/submit`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setDisBtn(() => false);
          setemptyField([]);
          // const Msg = res.data.Msg;
          navigate("/");
        })
        .catch((err) => {
          setDisBtn(() => false);
          setemptyField([]);
          // console.log(err.response);
          console.log(err, "error");
          if (
            err?.response?.data?.message?.includes(
              "SubmitListing validation failed"
            )
          ) {
            toast({
              title: "Error",
              description:
                "There seems to be a mix-up with your input. Make sure to use numbers where numbers are required and characters where characters are required.",
              status: "warning",
              duration: 3000,
              variant: "left-accent",
              position: "top-right",
              isClosable: true,
            });
          }
        })
        .finally(() => {
          setDisBtn(false);
          setemptyField([]); // Step 3: Reset the disbtn state after submission is complete or if an error occurs
        });
      setModal(false);
    }
  };
  const submitlistingdata = () => {
    let emptyArray = [
      { name: "land", value: land, text: "Land" },
      { name: "space", value: space, text: "Space" },
      // { name: "video", value: video, text: "Video" },
      // { name: "videofile", value: videofile, text: "Videofile" },
      { name: "location.address", value: location.address, text: "Address" },
      { name: "location.long", value: location.long, text: "Longitude" },
      { name: "location.lat", value: location.lat, text: "Latitude" },
      { name: "floorImages", value: floorImages, text: "Floor Files" },
      { name: "bbqarea", value: bbqarea, tab: "tab4", text: "Bbqarea" },
      { name: "partyhall", value: partyhall, tab: "tab4", text: "Partyhall" },
      {
        name: "communityHall",
        value: communityHall,
        tab: "tab4",
        text: "CommunityHall",
      },
      {
        name: "swimmingpools",
        value: swimmingpoolguest,
        tab: "tab4",
        text: "Swimmingpools",
      },
      { name: "guestgym", value: guestgym, tab: "tab4", text: "Guest gym" },
      {
        name: "guestParking",
        value: guestParking,
        tab: "tab4",
        text: "Guest Parking",
      },
      { name: "minimart", value: minimart, tab: "tab4", text: "Minimart" },
      {
        name: "libraryguest",
        value: libraryguest,
        tab: "tab4",
        text: "Libraryguest",
      },
      { name: "park", value: playGround, tab: "tab4", text: "Park" },
      {
        name: "Tennis Court",
        value: tenniscourt,
        tab: "tab4",
        text: "Tennis Court",
      },
      { name: "Fans", value: Aircondition, tab: "tab4", text: "Fans" },
      {
        name: "solarPower",
        value: solarPower,
        tab: "tab4",
        text: "SolarPower",
      },
      { name: "seaview", value: seaview, tab: "tab4", text: "Seaview" },
      // { name: "hotWater", value: hotWater, tab: "tab4", text: "HotWater" },
      // {
      //   name: "oceanFront",
      //   value: oceanFront,
      //   tab: "tab4",
      //   text: "OceanFront",
      // },
      {
        name: "Elevator",
        value: Elevator,
        tab: "tab4",
        text: "Elevator",
      },
      {
        name: "PentHouse",
        value: penthouse,
        tab: "tab4",
        text: "Pent House",
      },
      {
        name: "playGround",
        value: playGround,
        tab: "tab4",
        text: "PlayGround",
      },
      { name: "id", value: id, tab: "tab5", text: "Id" },
      { name: "beds", value: beds, tab: "tab5", text: "Beds" },
      { name: "baths", value: baths, tab: "tab5", text: "Baths" },
      { name: "story", value: story, tab: "tab5", text: "Story" },
      { name: "lotsize", value: lotsize, tab: "tab5", text: "Lotsize" },
      { name: "heater", value: heater, tab: "tab5", text: "Heater" },
      { name: "Kitchens", value: Kitchens, tab: "tab5", text: "Kitchens" },
      // { name: "built", value: bArea, tab: "tab5", text: "Built" },
      {
        name: "neighborhood",
        value: neighbor,
        tab: "tab5",
        text: "Neighborhood",
      },
      { name: "living", value: living, tab: "tab5", text: "Living" },
      { name: "dining", value: dining, tab: "tab5", text: "Dining" },
      {
        name: "laundryroom",
        value: laundryroom,
        tab: "tab5",
        text: "Laundryroom",
      },
      {
        name: "meetingroom",
        value: meetingroom,
        tab: "tab5",
        text: "Meetingroom",
      },
      { name: "gameroom", value: gameroom, tab: "tab5", text: "Gameroom" },
      { name: "jacuzzi", value: jacuzzi1, tab: "tab5", text: "Jacuzzi" },
      { name: "sauna", value: sauna, tab: "tab5", text: "Sauna" },
      { name: "maid", value: maid, tab: "tab5", text: "Maid" },
      { name: "guest", value: guest, tab: "tab5", text: "Guest" },
      {
        name: "propertybuilt",
        value: built,
        tab: "tab5",
        text: "Propertybuilt",
      },
      { name: "view", value: view, tab: "tab5", text: "View" },
      { name: "condition", value: condition, tab: "tab5", text: "Condition" },
      { name: "title", value: title, tab: "tab7", text: "Title" },
      { name: "slug", value: slug, tab: "tab7", text: "Slug" },
      { name: "metaTag", value: metaTag, tab: "tab7", text: "Meta Tag" },
      { name: "keyWords", value: keyWords, tab: "tab7", text: "Key Words" },
      // { name: "AgentThumbnails", text: "Agent Picture", tab: "tab6" },

      { name: "agentName", value: agentName, text: "Agent Name", tab: "tab6" },
      {
        name: "agentLastName",
        value: agentLastName,
        text: "Agent Last Name",
        tab: "tab6",
      },
      { name: "agentTel", value: agentTel, text: "Agent Tel", tab: "tab6" },
      {
        name: "agentProvince",
        value: agentProvince,
        text: "Agent Province",
        tab: "tab6",
      },
      {
        name: "agentCountry",
        value: agentCountry,
        text: "Country Province",
        tab: "tab6",
      },
      {
        name: "agentServices",
        value: AgentService,
        text: "Agent Service",
        tab: "tab6",
      },
      { name: "Line Id", value: lineid, text: "Line Id", tab: "tab6" },
      { name: "WeChat ID", value: wechatId, text: "We Chat Id", tab: "tab6" },
      {
        name: "WeChat",
        value: agentwhatapp,
        text: "Agent Whatapp",
        tab: "tab6",
      },
    ];
    if (!videofile && !video) {
      emptyArray.push(
        { name: "video", value: video, text: "Video" },
        { name: "videofile", value: videofile, text: "Videofile" }
      );
    }
    if (listingtype == "Rent") {
      emptyArray.push(
        {
          name: "paidwater",
          value: paidwater,
          tab: "tab4",
          text: "Paid Water",
        },
        {
          name: "Electricity",
          value: Electricity,
          tab: "tab4",
          text: "Electricity",
        }
      );
    }
    let emptyFields = emptyArray
      ?.filter(
        (item) =>
          item.value === "" ||
          (Array.isArray(item.value) && item.value.length === 0) ||
          item.value === null ||
          item.value === undefined
      )
      .map((item) => item.text);

    if (emptyFields?.length === 0) {
      setModal(false); // Close the modal
      submitData(); // Call submitData function to proceed
    } else {
      setemptyField(emptyFields); // Set empty fields in the state to highlight them or show messages
      setModal(true); // Keep the modal open
    }
  };

  const statusList1 = [
    { value: "Featured", label: "Featured" },
    { value: "For Sale", label: "For Sale" },
    { value: "Leased", label: "Leased" },
    { value: "New Addition", label: "New Addition" },
    { value: "Sold", label: "Sold" },
    // { value: "Rental", label: "Rental" },
    { value: "Reduced", label: "Reduced" },
    { value: "Special Offer", label: "Special Offer" },
  ];
  const statusList2 = [
    // {value:"",label:"Select Property status"},
    { value: "Featured", label: "Featured" },
    { value: "For Rent", label: "For Rent" },
    { value: "Leased", label: "Leased" },
    { value: "New Addition", label: "New Addition" },
    { value: "Sold", label: "Sold" },
    // { value: "Rental", label: "Rental" },
    { value: "Reduced", label: "Reduced" },
    { value: "Special Offer", label: "Special Offer" },
  ];

  const handleChange = (value) => {
    setStatus(value);
    const hasRental = value?.includes("Rental");
    setRental(hasRental);
  };

  const handleButtonClick = (buttonType) => {
    setlistingtype(buttonType);
  };

  // const handleCountryChange = (e) => {
  //   setCountryCode(e.target.value);
  // };

  // const countrycode = [
  //   "+66", // Thailand
  //   "+966", // Saudi Arabia
  //   "+1", // United States
  //   "+44", // United Kingdom
  //   "+92", // Pakistan
  //   "+91", // India
  //   "+33", // France
  //   "+49", // Germany
  //   "+81", // Japan
  //   "+86", // China
  //   "+61", // Australia
  //   "+34", // Spain
  //   "+39", // Italy
  //   "+7", // Russia
  //   "+82", // South Korea
  //   "+52", // Mexico
  //   "+55", // Brazil
  //   "+971", // United Arab Emirates
  //   "+968", // Oman
  //   "+20", // Egypt
  //   "+27", // South Africa
  //   "+30", // Greece
  //   "+31", // Netherlands
  //   "+32", // Belgium
  //   "+46", // Sweden
  //   "+47", // Norway
  //   "+48", // Poland
  //   "+54", // Argentina
  //   "+56", // Chile
  //   "+57", // Colombia
  //   "+58", // Venezuela
  //   "+60", // Malaysia
  //   "+63", // Philippines
  //   "+64", // New Zealand
  //   "+65", // Singapore
  //   "+81", // Japan
  //   "+82", // South Korea
  //   "+84", // Vietnam
  //   "+86", // China
  //   "+90", // Turkey
  //   "+91", // India
  //   "+92", // Pakistan
  //   "+93", // Afghanistan
  //   "+94", // Sri Lanka
  //   "+95", // Myanmar
  //   "+98", // Iran
  //   "+212", // Morocco
  //   "+213", // Algeria
  //   "+216", // Tunisia
  //   "+218", // Libya
  //   "+220", // Gambia
  //   "+221", // Senegal
  //   "+222", // Mauritania
  //   "+223", // Mali
  //   "+224", // Guinea
  //   "+225", // Ivory Coast
  //   "+226", // Burkina Faso
  //   "+227", // Niger
  //   "+228", // Togo
  //   "+229", // Benin
  //   "+230", // Mauritius
  //   "+231", // Liberia
  //   "+232", // Sierra Leone
  //   "+233", // Ghana
  //   "+234", // Nigeria
  //   "+235", // Chad
  //   "+236", // Central African Republic
  //   "+237", // Cameroon
  //   "+238", // Cape Verde
  //   "+239", // Sao Tome and Principe
  //   "+240", // Equatorial Guinea
  //   "+241", // Gabon
  //   "+242", // Republic of the Congo
  //   "+243", // Democratic Republic of the Congo
  //   "+244", // Angola
  //   "+245", // Guinea-Bissau
  //   "+246", // British Indian Ocean Territory
  //   "+247", // Ascension Island
  //   "+248", // Seychelles
  //   "+249", // Sudan
  //   "+250", // Rwanda
  //   "+251", // Ethiopia
  //   "+252", // Somalia
  //   "+253", // Djibouti
  //   "+254", // Kenya
  //   "+255", // Tanzania
  //   "+256", // Uganda
  //   "+257", // Burundi
  //   "+258", // Mozambique
  //   "+260", // Zambia
  //   "+261", // Madagascar
  //   "+262", // Reunion
  //   "+263", // Zimbabwe
  //   "+264", // Namibia
  //   "+265", // Malawi
  //   "+266", // Lesotho
  //   "+267", // Botswana
  //   "+268", // Eswatini
  //   "+269", // Comoros
  // ];

  const checkingFunction = (buttonType) => {
    const countryCodes = [
      "93",
      "355",
      "213",
      "376",
      "244",
      "1-268",
      "54",
      "374",
      "61",
      "43",
      "994",
      "1-242",
      "973",
      "880",
      "1-246",
      "375",
      "32",
      "501",
      "229",
      "975",
      "591",
      "387",
      "267",
      "55",
      "673",
      "359",
      "226",
      "257",
      "238",
      "855",
      "237",
      "1",
      "236",
      "235",
      "56",
      "86",
      "57",
      "269",
      "243",
      "242",
      "506",
      "385",
      "53",
      "357",
      "420",
      "45",
      "253",
      "1-767",
      "1-809",
      "593",
      "20",
      "503",
      "240",
      "291",
      "372",
      "268",
      "251",
      "679",
      "358",
      "33",
      "241",
      "220",
      "995",
      "49",
      "233",
      "30",
      "1-473",
      "502",
      "224",
      "245",
      "592",
      "509",
      "504",
      "36",
      "354",
      "91",
      "62",
      "98",
      "964",
      "353",
      "972",
      "39",
      "1-876",
      "81",
      "962",
      "7",
      "254",
      "686",
      "850",
      "82",
      "383",
      "965",
      "996",
      "856",
      "371",
      "961",
      "266",
      "231",
      "218",
      "423",
      "370",
      "352",
      "261",
      "265",
      "60",
      "960",
      "223",
      "356",
      "692",
      "222",
      "230",
      "52",
      "691",
      "373",
      "377",
      "976",
      "382",
      "212",
      "258",
      "95",
      "264",
      "674",
      "977",
      "31",
      "64",
      "505",
      "227",
      "234",
      "389",
      "47",
      "968",
      "92",
      "680",
      "507",
      "675",
      "595",
      "51",
      "63",
      "48",
      "351",
      "974",
      "40",
      "7",
      "250",
      "1-869",
      "1-758",
      "1-784",
      "685",
      "378",
      "239",
      "966",
      "221",
      "381",
      "248",
      "232",
      "65",
      "421",
      "386",
      "677",
      "252",
      "27",
      "211",
      "34",
      "94",
      "249",
      "597",
      "46",
      "41",
      "963",
      "886",
      "992",
      "255",
      "66",
      "670",
      "228",
      "676",
      "1-868",
      "216",
      "90",
      "993",
      "688",
      "256",
      "380",
      "971",
      "44",
      "1",
      "598",
      "998",
      "678",
      "39",
      "58",
      "84",
      "967",
      "260",
      "263",
    ];

    for (const code of countryCodes) {
      if (buttonType.startsWith(code)) {
        const number = buttonType.slice(code.length).trim(); // Extract number after the country code
        console.log(code);
        console.log(number);

        setCountryCode(code);
        setAgentTel(number);
        return;
      }
    }

    console.error("No matching country code found");
    setCountryCode("");
    setAgentTel("");
  };

  const handleFurnishedTypesChange = (value) => {
    setFurnishedTypes(value);
  };

  return (
    <div className="container">
      <div className="py-5 submitlisting-section">
        <Container maxW="80%">
          <div className="row submitlisting-row">
            <Tab.Container defaultActiveKey={tabKey} activeKey={tabKey}>
              {/* Tabs Start */}
              <div className="col-md-4">
                <Nav variant="tabs" className="nav nav-tabs tab-cards">
                  <Nav.Item>
                    <Nav.Link eventKey="tab1" onClick={() => setTabKey("tab1")}>
                      <span>01</span> {t("basicInformation")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab2" onClick={() => setTabKey("tab2")}>
                      <span>02</span> {t("gallery")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab3" onClick={() => setTabKey("tab3")}>
                      <span>03</span> {t("location")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab4" onClick={() => setTabKey("tab4")}>
                      <span>04</span> {t("propertyAmenity")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab5" onClick={() => setTabKey("tab5")}>
                      <span>05</span> {t("details")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab6" onClick={() => setTabKey("tab6")}>
                      <span>06</span> {t("agentDashboard")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab7" onClick={() => setTabKey("tab7")}>
                      <span>07</span> {t("seo")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab8" onClick={() => setTabKey("tab8")}>
                      <span>08</span> {t("Floor Plan")}
                    </Nav.Link>
                  </Nav.Item>
                  {/* {user && user.isAdmin === true && (
                   
                  )} */}
                </Nav>
              </div>
              {/* Tabs End */}
              {/* Tab Content Start */}
              <div className="col-md-8">
                <form onSubmit={(e) => e.preventDefault()}>
                  <Tab.Content className="m-0">
                    <Tab.Pane eventKey="tab1">
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <div className="d-flex justify-center">
                            <Button
                              // type="primary"
                              className={`mr-2 px-5  ${
                                listingtype === "Sell"
                                  ? "selected h-[50px]"
                                  : "h-[50px]"
                              }`}
                              onClick={() => handleButtonClick("Sell")}
                            >
                              Sell
                            </Button>
                            <Button
                              // type="primary"

                              className={`px-5  ${
                                listingtype === "Rent"
                                  ? "selected h-[50px]"
                                  : " h-[50px]"
                              }`}
                              onClick={() => handleButtonClick("Rent")}
                            >
                              Rent
                            </Button>
                          </div>
                        </div>
                        <div className="col-md-12 form-group">
                          <label>{t("PropertyDescription")}</label>
                          <textarea
                            name="content"
                            rows={4}
                            className="form-control"
                            placeholder={t("PropertyDescription")}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("PropertyName")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("PropertyName")}
                            required
                            name="name"
                            // value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("PropertyStatus")}</label>
                          {listingtype === "Rent" ? (
                            <Select
                              style={{ width: "100%" }}
                              placeholder={t("PropertyStatus")}
                              value={status}
                              onChange={handleChange}
                            >
                              {statusList2.map((status) => (
                                <Select.Option
                                  key={status.value}
                                  value={status.value}
                                >
                                  {t(status.label)}
                                </Select.Option>
                              ))}
                            </Select>
                          ) : (
                            <Select
                              style={{ width: "100%" }}
                              placeholder={t("PropertyStatus")}
                              value={status}
                              onChange={handleChange}
                            >
                              {statusList1.map((status) => (
                                <Select.Option
                                  key={status.value}
                                  value={status.value}
                                >
                                  {t(status.label)}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </div>
                        {/* <div className="col-md-6 form-group">
                          <label>{t("Listing Type")}</label>
                          <select
                          className="form-control"
                          name="status"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          {statusList.map((res, key) => (
                            <option key={key} value={res}>
                              {res}
                            </option>
                          ))}
                        </select>
                          <Select
                            mode="tags"
                            style={{
                              width: "100%",
                            }}
                            placeholder={t("Listing Type")}
                            onChange={(value) => setlistingtype(value)}
                          >
                            <Select.Option value="Buy">
                              {t("Buy")}
                            </Select.Option>
                            <Select.Option value="Rent">
                              {t("Rent")}
                            </Select.Option>
                            <Select.Option value="Sell">
                              {t("Sell")}
                            </Select.Option>
                          </Select>
                        </div> */}
                        <div className="col-md-6">
                          <label>{t("PropertyType")}</label>
                          <select
                            className="form-control"
                            name="type"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value={""}>{t("PropertyType")}</option>
                            {typeList.map((res, key) => (
                              <option key={key} value={res.name}>
                                {res.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {listingtype == "Rent" ? (
                          <>
                            <div className="col-md-6 form-group">
                              <label>{t("RentalPrice")}</label>
                              <div className=" d-flex flex-row">
                                <div className="input-group-prepend w-[15%]">
                                  <select
                                    className="form-control px-1 text-center"
                                    name="type"
                                    value={currency}
                                    onChange={(e) =>
                                      setCurrency(e.target.value)
                                    }
                                  >
                                    {currencyList.map((res, key) => (
                                      <option key={key} value={res.symbol}>
                                        {res.symbol}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <input
                                  className="form-control w-[85%]"
                                  name="period"
                                  type="text"
                                  // value={rentalPrice}
                                  onChange={(e) =>
                                    setRentalPrice(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label>{t("Rental Period")}</label>
                              <select
                                className="form-control"
                                name="period"
                                // value={period}
                                onChange={(e) => setPeriod(e.target.value)}
                              >
                                <option value="Monthly">{t("Monthly")}</option>
                                <option value="Weekly">{t("Weekly")}</option>
                                <option value="Daily">{t("Daily")}</option>
                              </select>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                         {listingtype !== "Rent" ? (
                          <div className="col-md-6 form-group">
                            <label>{t("SalePrice/RentalPrice")}</label>
                            <div className="d-flex flex-rowp">
                              <div className="input-group-prepend w-[15%]">
                                <select
                                  className="form-control px-1 text-center"
                                  name="type"
                                  value={currency}
                                  onChange={(e) => setCurrency(e.target.value)}
                                >
                                  {currencyList.map((res, key) => (
                                    <option key={key} value={res.symbol}>
                                      {res.symbol}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <input
                                className="form-control w-[85%]"
                                name="price"
                                type="number"
                                placeholder={t("SalePrice/RentalPrice")}
                                // value={price}
                                onChange={(e) => setPrice(e.target.value)}
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {listingtype !== "Rent" ? (
                          <div className="col-md-6">
                            <label>{t("Monthly Mortage")}</label>

                            <div className="d-flex flex-row">
                              <div className="input-group-prepend w-[15%] ">
                                <select
                                  className="form-control px-1 text-center"
                                  name="type"
                                  value={currencymonthly}
                                  onChange={(e) =>
                                    setCurrencymonthly(e.target.value)
                                  }
                                >
                                  {currencyList.map((res, key) => (
                                    <option key={key} value={res.symbol}>
                                      {res.symbol}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <input
                                className="form-control w-[85%]"
                                name="period"
                                type="number"
                                value={MonthlyMortage}
                                onChange={(e) =>
                                  setMonthlyMortage(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-6 form-group">
                          <label>{t("Maintenance Fee")}</label>
                          <div className=" d-flex flex-row">
                            <div className="w-[15%]">
                              <select
                                className="form-control px-1 text-center"
                                name="type"
                                value={currencyMaintainance}
                                onChange={(e) =>
                                  setcurrencyMaintainance(e.target.value)
                                }
                              >
                                {currencyList?.map((res, key) => (
                                  <option key={key} value={res.symbol}>
                                    {res.symbol}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input
                              className="form-control px-3 w-[85%]"
                              name="period"
                              type="number"
                              value={maintenanceFee}
                              onChange={(e) =>
                                setMaintenanceFee(e.target.value)
                              }
                            />
                          </div>
                        </div>
                       
                         {listingtype === "Rent" ? (
                          <>
                            <div className="col-md-6">
                              <label>{t("Furnished")}</label>
                              <select
                                className="form-control"
                                name="furnished"
                                value={furnished}
                                onChange={(e) => setFurnished(e.target.value)}
                              >
                                <option>{t("Select the value")}</option>
                                <option value="yes">{t("Yes")}</option>
                                <option value="no">{t("No")}</option>
                              </select>
                            </div>
                            {furnished === "yes" && (
                              <div className="col-md-6">
                                <label>{t("Type of Furnish")}</label>
                                <Select
                                  mode="multiple"
                                  placeholder="Select an option"
                                  style={{ width: "100%" }}
                                  onChange={handleFurnishedTypesChange}
                                >
                                  <Option value="Stove">{t("Stove")}</Option>
                                  <Option value="Refrigerator">
                                    {t("Refrigerator")}
                                  </Option>
                                  <Option value="Air Condition">
                                    {t("Air Condition")}
                                  </Option>
                                  <Option value="Bed">{t("Bed")}</Option>
                                  <Option value="TV">{t("TV")}</Option>
                                </Select>
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="col-md-6 form-group">
                          <label>Min-Price</label>
                          <div className=" d-flex flex-row">
                            <div className="input-group-prepend w-[15%]">
                              <select
                                className="form-control px-1 text-center"
                                name="type"
                                value={currencyMin}
                                onChange={(e) => setCurrencyMin(e.target.value)}
                              >
                                {currencyList?.map((res, key) => (
                                  <option key={key} value={res.symbol}>
                                    {res.symbol}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input
                              className="form-control w-[85%]"
                              name="period"
                              type="number"
                              value={minPrice}
                              onChange={(e) => setMinPrice(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>Max-Price</label>
                          <div className="d-flex flex-row">
                            <div className="input-group-prepend w-[15%]">
                              <select
                                className="form-control px-1 text-center"
                                name="type"
                                value={currencyMin}
                                onChange={(e) => setCurrencyMin(e.target.value)}
                              >
                                {currencyList?.map((res, key) => (
                                  <option key={key} value={res.symbol}>
                                    {res.symbol}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input
                              className="form-control w-[85%]"
                              name="period"
                              type="number"
                              value={maxPrice}
                              onChange={(e) => setMaxPrice(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("LandArea")}</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder={t("LandArea")}
                            name="space"
                            // value={land}
                            onChange={(e) => setLand(e.target.value)}
                          />
                          <div
                            style={{
                              display: "flex",
                              width: "60px",
                              marginTop: "10px",
                            }}
                          >
                            <img src={bathoom} />
                            <img src={bedroom} />
                          </div>
                        </div>
                        
                        {(type == "Condo" || type == "Apartment") && (
                          <div
                            className={`col-md-6 form-group ${
                              listingtype === "Sell" ? "mt-0" : "mt-0"
                            }`}
                          >
                            <label>{t("Room#")}</label>
                            <div className="input-group">
                              <input
                                className="form-control"
                                name="period"
                                type="number"
                                value={roomNo}
                                onChange={(e) => setroomNo(e.target.value)}
                              />
                            </div>
                          </div>
                        )}
                        {(type == "Condo" ||
                          type == "Apartment" ||
                          type == "Flats") && (
                          <div
                            className={`col-md-6 form-group ${
                              listingtype === "Sell" ? "mt-0" : "mt-0"
                            }`}
                          >
                            <label>{t("ROOM AREA")} (SQM)</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("ROOM AREA")}
                              name="space"
                              // value={space}
                              onChange={(e) => setSpace(e.target.value)}
                            />
                          </div>
                        )}
                        {(type == "Condo" || type == "Apartment") && (
                          <div className="col-md-6 form-group ">
                            <label>{t("Room Floor")}</label>
                            <input
                              type="number"
                              className="form-control"
                              name="buildstory"
                              value={RoomFloor}
                              onChange={(e) => setRoomFloor(e.target.value)}
                            />
                          </div>
                        )}
                        <div className="col-md-12 form-group">
                          <label>{t("PropertyVideo")}</label>
                          <input
                            type="text"
                            className="form-control mb-2"
                            placeholder={t("PropertyVideolink")}
                            name="video"
                            // value={video}
                            onChange={(e) => setVideo(e.target.value)}
                          />
                          <input
                            type="file"
                            className="form-control"
                            placeholder="PropertyVideo"
                            name="videofile"
                            accept="video/*"
                            onChange={handlefile}
                          />
                        </div>
                        <div className="col-md-12 form-group">
                          <div className="w-25">
                            <button className="btn btn-primary px-2 py-2 w-100">
                              <Nav.Link
                                eventKey="tab4"
                                onClick={() => setTabKey("tab2")}
                              >
                                {t("save")}
                              </Nav.Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab2">
                      <div className="form-group row">
                        <label>{t("Property Thumbnail")}</label>
                        <div className="custom-file col-md-4 col-lg-3">
                          <input
                            type="file"
                            className="custom-file-input d-none"
                            id="propertyThumbnail"
                            multiple
                            onChange={handleThumbnailChange}
                          />
                          <label
                            className="custom-file-label cursor-pointer"
                            htmlFor="propertyThumbnail"
                          >
                            Choose File
                          </label>
                        </div>
                        <div className="img-preview d-flex flex-wrap">
                          {thumbnailUrl &&
                            thumbnailUrl.map((url, index) => (
                              <div key={index} className="thumbnail-container">
                                <img
                                  src={url}
                                  alt={`Thumbnail ${index + 1}`}
                                  className="thumbnail-img"
                                  style={{ width: "100px", aspectRatio: "3/3" }}
                                />
                                <button
                                  style={{
                                    backgroundColor: "#ADD8E6",
                                    height: "25%",
                                    width: "100%",
                                  }}
                                  onClick={() => handleDeleteThumbnail(index)}
                                >
                                  Delete
                                </button>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>{t("Property Gallery")}</label>
                        <div
                          {...getPropertyRootProps({
                            className: "dropzone cursor-pointer",
                          })}
                        >
                          <input {...getPropertyInputProps(-5)} multiple />
                          <div className="dropzone-msg dz-message needsclick">
                            <i className="fas fa-cloud-upload-alt" />
                            <h5 className="dropzone-msg-title">
                              {t("Drop files here or click to upload.")}
                            </h5>
                            <span className="dropzone-msg-desc">
                              {t(
                                "This is just a demo dropzone. Selected files are not actually uploaded."
                              )}
                            </span>
                          </div>
                        </div>
                        <aside
                          style={{ display: "flex" }}
                          className={thumbsContainer}
                        >
                          {thumbs}
                        </aside>
                        <span className="acr-form-notice">
                          {t("You can upload up to 15 images for your listing")}
                        </span>
                        <span className="acr-form-notice">
                          {t(
                            "Listing images should be at least 620x480 in dimensions"
                          )}
                        </span>
                      </div>
                      <div className="col-md-12 form-group">
                        <div className="w-25">
                          <button className="btn btn-primary px-2 py-2 w-100">
                            <Nav.Link
                              eventKey="tab4"
                              onClick={() => setTabKey("tab3")}
                            >
                              {t("save")}
                            </Nav.Link>
                          </button>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab3">
                      <Locationtab locationData={locationData} />
                      <div className="col-md-12 form-group">
                        <div className="w-25">
                          <button className="btn btn-primary px-2 py-2 w-100">
                            <Nav.Link
                              eventKey="tab4"
                              onClick={() => setTabKey("tab4")}
                            >
                              {t("save")}
                            </Nav.Link>
                          </button>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab4">
                      <div className="row">
                        {/* {featureList.map((res, key) => (
    <div key={key} className="col-lg-4 col-md-6 col-sm-6">
      <label className="acr-listing-feature">
        <input
          type="checkbox"
          name={"feature" + res._id + ""}
          onClick={() => featuresData(res._id)}
        />
        <i className="acr-feature-check fas fa-check" />
        <i style={{ textAlign: "-webkit-center" }}>
          <img
            className="acr-listing-feature-icon"
            src={`${res.icon}`}
            alt="ThumbImage"
            style={{ marginBottom: "20px", width: '200px', height: '200px' }}
          />
        </i>
        {t(res.name)}
      </label>
    </div>
  ))} */}

                        <div className="col-md-6 form-group">
                          <label>{t("BBQ Area")}</label>
                          <select
                            className="form-control"
                            name="bbqarea"
                            value={bbqarea}
                            onChange={(e) => setBBQArea(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Party Hall")}</label>
                          <select
                            className="form-control"
                            name="partyhall"
                            value={partyhall}
                            onChange={(e) => setPartyHall(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Community Hall")}</label>
                          <select
                            className="form-control"
                            name="community hall"
                            value={communityHall}
                            onChange={(e) => setCommunityHall(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Swimming Pool")}</label>
                          <select
                            className="form-control"
                            name="swimmingpools"
                            value={swimmingpoolguest}
                            onChange={(e) =>
                              setSwimmingpoolguest(e.target.value)
                            }
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Gym")}</label>
                          <select
                            className="form-control"
                            name="guestgym"
                            value={guestgym}
                            onChange={(e) => setGymguest(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Guest Parking")}</label>
                          <select
                            className="form-control"
                            name="guestParking"
                            value={guestParking}
                            onChange={(e) => setGuestParking(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Mini Mart")}</label>
                          <select
                            className="form-control"
                            name="minimart"
                            value={minimart}
                            onChange={(e) => setMinimart(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Library")}</label>
                          <select
                            className="form-control"
                            name="libraryguest"
                            value={libraryguest}
                            onChange={(e) => setLibraryguest(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Park")}</label>
                          <select
                            className="form-control"
                            name="park"
                            value={playGround}
                            onChange={(e) => setPlayGround(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Parks")}</label>
                          <select
                            className="form-control"
                            name="park"
                            value={park}
                            onChange={(e) => setPark(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Tennis Court")}</label>
                          <select
                            className="form-control"
                            name="Tennis Court"
                            value={tenniscourt}
                            onChange={(e) => settenniscourt(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        {/* <div className="col-md-6 form-group">
                          <label>{t("Jacuzzi")}</label>
                          <select
                            className="form-control"
                            name="Jacuzzi"
                            value={jacuzzi1}
                            onChange={(e) => setJacuzzi1(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div> */}
                        {/* <div className="col-md-6 form-group">
                          <label>{t("Sauna")}</label>
                          <select
                            className="form-control"
                            name="Sauna"
                            value={sauna1}
                            onChange={(e) => setsauna1(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div> */}
                        {/* <div className="col-md-6 form-group">
                          <label>{t("Sauna")}</label>
                          <select
                            className="form-control"
                            name="Sauna"
                            value={sauna}
                            onChange={(e) => setSauna(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div> */}
                        <div className="col-md-6 form-group">
                          <label>{t("FansAirCondition")}</label>
                          <select
                            className="form-control"
                            name="Fans"
                            value={Aircondition}
                            onChange={(e) => setAircondition(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("solarPower")}</label>
                          <select
                            className="form-control"
                            name="solarPower"
                            value={solarPower}
                            onChange={(e) => setsolarPower(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("seaview")}</label>
                          <select
                            className="form-control"
                            name="seaview"
                            value={seaview}
                            onChange={(e) => setseaview(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Elevator")}</label>
                          <select
                            className="form-control"
                            name="Elevator"
                            value={Elevator}
                            onChange={(e) => setElevator(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        {listingtype == "Rent" && (
                          <div className="col-md-6 form-group">
                            <label>{t("electrcity")}</label>
                            <select
                              className="form-control"
                              name="Electricity"
                              value={Electricity}
                              onChange={(e) => setElectricity(e.target.value)}
                            >
                              <option value="">Select value</option>
                              <option value="true">{t("Yes")}</option>
                              <option value="false">{t("No")}</option>
                            </select>
                          </div>
                        )}
                        {listingtype == "Rent" && (
                          <div className="col-md-6 form-group">
                            <label>{t("paidwater")}</label>
                            <select
                              className="form-control"
                              name="paidwater"
                              value={paidwater}
                              onChange={(e) => setpaidwater(e.target.value)}
                            >
                              <option value="">Select value</option>
                              <option value="true">{t("Yes")}</option>
                              <option value="false">{t("No")}</option>
                            </select>
                          </div>
                        )}
                        <div className="col-md-6 form-group">
                          <label>{t("Pent House")}</label>
                          <select
                            className="form-control"
                            name="PentHouse"
                            value={penthouse}
                            onChange={(e) => setpenthouse(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Kitchens")}</label>
                          <select
                            className="form-control"
                            name="Kitchens"
                            value={Kitchens}
                            onChange={(e) => setKitchens(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Heater")}</label>
                          <select
                            className="form-control"
                            name="heater"
                            value={heater}
                            onChange={(e) => setheater(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-12 form-group">
                          <div className="w-25">
                            <button className="btn btn-primary px-2 py-2 w-100">
                              <Nav.Link
                                eventKey="tab4"
                                onClick={() => setTabKey("tab5")}
                              >
                                {t("save")}
                              </Nav.Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab5">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label>{t("Property ID")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Property ID")}
                            name="id"
                            // value={id}
                            onChange={(e) => setId(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Beds")}</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder={t("Number of Beds")}
                            name="beds"
                            // value={beds}
                            onChange={(e) => setBeds(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Bathrooms")}</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder={t("Number of Bathrooms")}
                            name="baths"
                            // value={baths}
                            onChange={(e) => setBaths(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Condition")}</label>
                          <select
                            className="form-control"
                            onChange={(e) => setCondition(e.target.value)}
                            name="condition"
                          >
                            <option value="">{t("please Select")}</option>
                            <option value="Used">{t("Used")}</option>
                            <option value="New">{t("New")}</option>
                            <option value="Satisfactory">
                              {t("Satisfactory")}
                            </option>
                            <option value="Fixer upper">
                              {t("Fixer upper")}
                            </option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Year Built")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Property Year Built")}
                            name="propertybuilt"
                            value={built}
                            onChange={(e) => setBuilt(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Neighborhood")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Property Neighborhood")}
                            name="neighborhood"
                            // value={neighbor}
                            onChange={(e) => setNeighbor(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Living Room")}</label>
                          {/* <select
                            className="form-control"
                            name="livingRoom"
                            value={living}
                            onChange={(e) => setLiving(e.target.value)}
                          >
                            {" "}
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select> */}
                          <input
                            type="text"
                            className="form-control"
                            name="livingRoom"
                            // value={living}
                            onChange={(e) => setLiving(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Dining Room")}</label>
                          {/* <select
                            className="form-control"
                            name="diningRoom"
                            value={dining}
                            onChange={(e) => setDining(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select> */}
                          <input
                            className="form-control"
                            name="diningRoom"
                            //  value={dining}
                            onChange={(e) => setDining(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Laundry Room")}</label>
                          <select
                            className="form-control"
                            name="laundryroom"
                            value={laundryroom}
                            onChange={(e) => setLaundryroom(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Meeting room")}</label>
                          <select
                            className="form-control"
                            name="meetingroom"
                            value={meetingroom}
                            onChange={(e) => setMeetingroom(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Game Room")}</label>
                          <select
                            className="form-control"
                            name="gameroom"
                            value={gameroom}
                            onChange={(e) => setGameroom(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Jacuzzi")}</label>
                          <select
                            className="form-control"
                            name="Jacuzzi"
                            value={jacuzzi1}
                            onChange={(e) => setJacuzzi1(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        {/* <div className="col-md-6 form-group">
                          <label>{t("Building area")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Building area")}
                            name="built"
                            // value={bArea}
                            onChange={(e) => setbArea(e.target.value)}
                          />
                        </div> */}
                       
                        {/* Add translations for other fields as needed */}
                       
                        <div className="col-md-6 form-group">
                          <label>{t("Sauna")}</label>
                          <select
                            className="form-control"
                            name="Sauna"
                            value={sauna}
                            onChange={(e) => setSauna(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        
                        {/* <div className="col-md-6 form-group">
                          <label>{t("Jacuzzi")}</label>
                          <select
                            className="form-control"
                            name="jacuzzi"
                            value={jacuzzi}
                            onChange={(e) => setJacuzzi(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div> */}
                        {/* <div className="col-md-6 form-group">
                          <label>{t("Sauna")}</label>
                          <select
                            className="form-control"
                            name="sauna"
                            value={sauna}
                            onChange={(e) => setSauna(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div> */}
                        <div className="col-md-6 form-group">
                          <label>{t("Maid Room")}</label>
                          <select
                            className="form-control"
                            name="maidroom"
                            value={maid}
                            onChange={(e) => setMaid(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Guest room")}</label>
                          <select
                            className="form-control"
                            name="guestroom"
                            value={guest}
                            onChange={(e) => setGuest(e.target.value)}
                          >
                            {" "}
                            <option value="">Select value</option>
                            <option value="true">{t("Yes")}</option>
                            <option value="false">{t("No")}</option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Building Floor")}</label>
                          <input
                            type="number"
                            className="form-control"
                            name="buildstory"
                            // value={story}
                            onChange={(e) => setStory(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Parking")}</label>
                          <select
                            onChange={(e) => setParking(e.target.value)}
                            className="form-control"
                          >
                            <option>{t("Parking")}</option>
                            <option value="1-2 Car Garage">
                              {t("1-2 Car Garage")}
                            </option>
                            <option value="3 Car Garage">
                              {t("3 Car Garage")}
                            </option>
                            <option value="4 Car Garage">
                              {t("4 Car Garage")}
                            </option>
                            <option value="5 Car Garage">
                              {t("5 and More")}
                            </option>
                          </select>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>
                            {listingtype === "Sell" &&
                            (type === "Apartment" ||
                              type === "Condo" ||
                              type === "Flats")
                              ? t("Roomsize")
                              : t("SQMBuildingSize")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              listingtype === "Sell" &&
                              (type === "Apartment" ||
                                type === "Condo" ||
                                type === "Flats")
                                ? t("Roomsize")
                                : t("SQMBuildingSize")
                            }
                            name="lotsize"
                            // value={lotsize}
                            onChange={(e) => setLotsize(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("View")}</label>
                          <select
                            onChange={(e) => setView(e.target.value)}
                            className="form-control"
                            name="view"
                          >
                            <option>{t("View")}</option>
                            <option value="Beach">{t("Beach")}</option>
                            <option value="Mountain">{t("Mountain")}</option>
                            <option value="Lake">{t("Lake")}</option>
                            <option value="Cities Life">
                              {t("Cities Life")}
                            </option>
                            <option value="Rural hills">
                              {t("Rural hills")}
                            </option>
                          </select>
                        </div>
                      
                      </div>
                      <div className="container">
                        <div className="row">
                          <label>{t("enterNearby")}</label>
                          <table
                            className="table table-bordered text-center"
                            style={{ margin: "0px 0px" }}
                          >
                            <thead>
                              <tr className="roof">
                                <th className="col-3">{t("selectType")}</th>
                                <th className="col-4">{t("name")}</th>
                                <th className="col-2">{t("distance")}</th>
                                <th className="col-3">
                                  <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={addRow}
                                  >
                                    {t("addRow")}
                                  </button>{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {nears.rows.map((item, idx) => (
                                <>
                                  <tr key={idx}>
                                    <td>
                                      <select
                                        className="form-control"
                                        value={item.type}
                                        name="type"
                                        disabled={nears.rows[idx].isEdit}
                                        onChange={(e) => {
                                          const multy = [...nears.rows];
                                          multy[idx].neartype = e.target.value;
                                          setNears({
                                            rows: [...multy],
                                          });
                                        }}
                                      >
                                        {nearTypeList.map((res, key) => (
                                          <option key={key} value={res._id}>
                                            {res.name}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={item.name}
                                        disabled={nears.rows[idx].isEdit}
                                        onChange={(e) => {
                                          const multy = [...nears.rows];
                                          multy[idx].name = e.target.value;
                                          setNears({
                                            rows: [...multy],
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="distance"
                                        value={item.distance}
                                        disabled={nears.rows[idx].isEdit}
                                        onChange={(e) => {
                                          const multy = [...nears.rows];
                                          multy[idx].distance = e.target.value;
                                          setNears({
                                            rows: [...multy],
                                          });
                                        }}
                                      />
                                    </td>
                                    <td
                                      className="td-valid"
                                      style={{ display: "flex", gap: "7px" }}
                                    >
                                      <button
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                          enableEdit(e, idx);
                                        }}
                                        style={{ borderRadius: "5px" }}
                                      >
                                        {t("Save")}
                                      </button>
                                      <button
                                        className="btn btn-danger"
                                        onClick={(e) => removeRow(e, idx)}
                                        style={{ borderRadius: "5px" }}
                                      >
                                        {t("delete")}
                                      </button>
                                    </td>
                                  </tr>
                                  <td>
                                    {nears.rows[0]?.neartype ==
                                      "64db14b79e7ad116420e7a18" && (
                                      <select
                                        defaultValue={envormentalValue}
                                        onChange={(e) =>
                                          setEnvormentalValue(e.target.value)
                                        }
                                        className="form-control"
                                      >
                                        {nearTypeList[5].Sublist.map(
                                          (res, key) => (
                                            <option key={key} value={res}>
                                              {res}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    )}
                                  </td>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="form-group" style={{ marginTop: "30px" }}>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="termsAndConditions"
                            checked={checkbox}
                            name="firstCheckbox"
                            onClick={() => setOpen(true)}
                          />
                          <label
                            className="custom-control-label ms-2"
                            htmlFor="termsAndConditions"
                          >
                            {t("agree")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 form-group">
                        <div className="w-25">
                          <button className="btn btn-primary px-2 py-2 w-100">
                            <Nav.Link
                              eventKey="tab4"
                              onClick={() => setTabKey("tab6")}
                            >
                              {t("save")}
                            </Nav.Link>
                          </button>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab6">
                      <div className="row">
                        <div className="custom-file col-md-12 col-lg-12">
                          <label className="">
                            {t("Agent Thumbnail Picture")}
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="propertyThumbnails"
                            onChange={async (e) => {
                              const base64 = await convertToBase64(
                                e.target.files[0]
                              );
                              setagentThumbnailUrl(base64);
                              setagentThumbnail(e.target.files[0]);
                            }}
                            style={{ display: "none" }}
                            name="AgentThumbnails"
                          />
                          <label
                            className="custom-file-label cursor-pointer"
                            htmlFor="propertyThumbnails"
                          >
                            {t("Choose File")}
                          </label>
                        </div>
                        {agentthumbnailUrl ? (
                          <div className="row">
                            <img
                              className="col-lg-12 col-md-12"
                              src={agentthumbnailUrl}
                              alt={t("ThumbImage")}
                              style={{ width: "30%" }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-6 cls form-group">
                          <label>{t("Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Name")}
                            name="agentName"
                            // value={agentName}
                            onChange={(e) => setAgentName(e.target.value)}
                          />
                        </div>

                        <div className="col-md-6 form-group">
                          <label>{t("Last Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Last Name")}
                            name="agentLastName"
                            // value={agentLastName}
                            onChange={(e) => setAgentLastName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Tel Number")}</label>
                          {/* <PhoneInput
                              country={countryCode} // Default country code
                              value={agentTel}
                              onChange={(value, country) => {
                                setAgentTel(value);
                                setCountryCode(country.countryCode);
                              }}
                              inputStyle={{ width: "100%" }} // Adjust input width
                              placeholder={t("Tel Number")}
                            /> */}
                          {toggleTelephone ? (
                            <PhoneInput
                              country={"th"}
                              value={agentTel}
                              onChange={(phone) => setAgentTel(phone)}
                              inputStyle={{ width: "100%" }}
                              placeholder="WeChat ID"
                              name="agentTel"
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Tel Number")}
                              value={agentTel}
                              onChange={(e) => setAgentTel(e.target.value)}
                            />
                          )}
                          <button
                            onClick={() => setTelephone((prev) => !prev)}
                            className={` ${
                              toggleTelephone
                                ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                                : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                            }`}
                          >
                            {toggleTelephone
                              ? "Switch to Text Input"
                              : "Switch to Phone Input"}
                          </button>
                        </div>
                        {/* <div className="col-md-6 form-group">
                          <label>{t("Tel Number")}</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder={t("Tel Number")}
                            name="agentTel"
                            // value={agentTel}
                            onChange={(e) => setAgentTel(e.target.value)}
                          />
                        </div> */}
                        <div className="col-md-6 form-group">
                          <label>{t("Province")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Province")}
                            name="agentProvince"
                            // value={agentProvince}
                            onChange={(e) => setAgentProvince(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Country")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Country")}
                            name="agentCountry"
                            // value={agentCountry}
                            onChange={(e) => setAgentCountry(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Agent Service")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Agent Service")}
                            name="agentServices"
                            // value={agentCountry}
                            onChange={(e) => setAgentService(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Line Id")}</label>
                          {toggleLineId ? (
                            <PhoneInput
                              country={"th"}
                              value={lineid}
                              onChange={(phone) => setlineid(phone)}
                              inputStyle={{ width: "100%" }}
                              placeholder="Line Id"
                              name="Line Id"
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Line Id"
                              value={lineid}
                              onChange={(e) => setlineid(e.target.value)}
                            />
                          )}
                          <button
                            onClick={() => setToggleLineId((prev) => !prev)}
                            className={` ${
                              toggleLineId
                                ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                                : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                            }`}
                          >
                            {toggleLineId
                              ? "Switch to Text Input"
                              : "Switch to Phone Input"}
                          </button>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("whatsapp")}</label>
                          {toggleWechatId ? (
                            <PhoneInput
                              country={"th"}
                              value={wechatId}
                              onChange={(phone) => setwechatId(phone)}
                              inputStyle={{ width: "100%" }}
                              placeholder="WeChat ID"
                              name="WeChat ID"
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="WeChat ID"
                              value={wechatId}
                              onChange={(e) => setwechatId(e.target.value)}
                            />
                          )}
                          <button
                            onClick={() => setToggleWechatId((prev) => !prev)}
                            className={` ${
                              toggleWechatId
                                ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                                : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                            }`}
                          >
                            {toggleWechatId
                              ? "Switch to Text Input"
                              : "Switch to Phone Input"}
                          </button>
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("WeChat")}</label>
                          {toggleAgentWhatsApp ? (
                            <PhoneInput
                              country={"th"}
                              value={agentwhatapp}
                              onChange={(phone) => setAgentWhatsApp(phone)}
                              inputStyle={{ width: "100%" }}
                              placeholder={t("WeChat")}
                              name="WeChat"
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("WeChat")}
                              value={agentwhatapp}
                              onChange={(e) => setAgentWhatsApp(e.target.value)}
                            />
                          )}
                          <button
                            onClick={() =>
                              setToggleAgentWhatsApp((prev) => !prev)
                            }
                            className={` ${
                              toggleAgentWhatsApp
                                ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                                : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                            }`}
                          >
                            {toggleAgentWhatsApp
                              ? "Switch to Text Input"
                              : "Switch to Phone Input"}
                          </button>
                        </div>
                      </div>
                      <div className="form-group" style={{ marginTop: "30px" }}>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="termsAndConditions"
                            checked={checkbox}
                            onClick={() => setOpen(true)}
                          />
                          <label
                            className="custom-control-label ms-2"
                            htmlFor="termsAndConditions"
                          >
                            {t("agree")}
                          </label>
                        </div>
                      </div>
                      <div>
                        {error === undefined || false ? (
                          ""
                        ) : (
                          <div
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "80%",
                                backgroundColor: "#FF3131",
                                color: "white",
                                padding: "10px 20px 10px 20px",
                                borderRadius: "5px",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                {fieldError
                                  ? " Please Fill Out All Fields"
                                  : ""}
                              </span>
                              <span>
                                {minFileError
                                  ? " Please Select Images for Thumbnail or Gallery"
                                  : ""}
                              </span>
                              <span>
                                {maxFileError
                                  ? "You can select only 5 pictures"
                                  : ""}
                              </span>
                              <button
                                style={{
                                  border: "white 2px solid",
                                  borderRadius: "25px",
                                  width: "35px",
                                  backgroundColor: "#FF3131",
                                  color: "white",
                                  fontSize: "15px",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  setError(undefined);
                                  setFieldError(undefined);
                                  setMinFileError(undefined);
                                  setMaxFileError(undefined);
                                }}
                              >
                                x
                              </button>
                            </div>
                          </div>
                        )}
                        {/* {user && !user?.isAdmin ? (
                          disbtn ? (
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            <button
                              className="btn-custom"
                              name="submit"
                              onClick={(e) => {
                                submitData(e);
                              }}
                              disabled={disbtn} // Disable the button while loading
                            >
                              {disbtn ? (
                                <span>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>{" "}
                                  {t("Submitting...")}
                                </span>
                              ) : (
                                t("submitListing")
                              )}
                            </button>
                          )
                        ) : null}
                        {!user ? (
                          disbtn ? (
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            <button
                              className="btn-custom"
                              name="submit"
                              onClick={(e) => {
                                submitData(e);
                              }}
                              disabled={disbtn} // Disable the button while loading
                            >
                              {disbtn ? (
                                <span>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>{" "}
                                  {t("Submitting...")}
                                </span>
                              ) : (
                                t("submitListing")
                              )}
                            </button>
                          )
                        ) : null} */}
                      </div>
                      <div className="col-md-12 form-group">
                        <div className="w-25">
                          <button className="btn btn-primary px-2 py-2 w-100">
                            <Nav.Link
                              eventKey="tab4"
                              onClick={() => setTabKey("tab7")}
                            >
                              {t("save")}
                            </Nav.Link>
                          </button>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab7">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label>{t("Title")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Title")}
                            name="id"
                            // value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Slug")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Slug")}
                            name="id"
                            // value={slug}
                            onChange={(e) => setSlug(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Meta Tag")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Meta Tag")}
                            name="id"
                            // value={metaTag}
                            onChange={(e) => setMetaTag(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>{t("Meta keywords")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Meta Keywords")}
                            name="id"
                            // value={keyWords}
                            onChange={(e) => setKeyWords(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 form-group">
                        <label>Description</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          name="id"
                          // value={descriptions}
                          onChange={(e) => setdescriptions(e.target.value)}
                        />
                      </div>
                      </div>
                      <div className="form-group" style={{ marginTop: "30px" }}>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="termsAndConditions"
                            checked={checkbox}
                            onClick={() => setOpen(true)}
                          />
                          <label
                            className="custom-control-label ms-2"
                            htmlFor="termsAndConditions"
                          >
                            {t("agree")}
                          </label>
                        </div>
                      </div>
                      {/* {user && user.isAdmin == true && disbtn ? (
                        <>
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn-custom"
                            name="submit"
                            onClick={(e) => {
                              submitData(e);
                            }}
                            disabled={disbtn} // Disable the button while loading
                          >
                            {disbtn ? (
                              <span>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>{" "}
                                {t("Submitting...")}
                              </span>
                            ) : (
                              t("submitListing")
                            )}
                          </button>
                        </>
                      )} */}

                      {/* {user && user.isAdmin ? ( */}
                      {/* ) : (
                        <></>
                      )} */}
                      <div className="col-md-12 form-group">
                        <div className="w-25">
                          <button className="btn btn-primary px-2 py-2 w-100">
                            <Nav.Link
                              eventKey="tab4"
                              onClick={() => setTabKey("tab8")}
                            >
                              {t("save")}
                            </Nav.Link>
                          </button>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab8">
                      <div className="form-group">
                        <label>{t("Floor Images")}</label>
                        <div
                          {...getFloorRootProps({
                            className: "dropzone cursor-pointer",
                          })}
                        >
                          <input {...getFloorInputProps(-5)} multiple />
                          <div className="dropzone-msg dz-message needsclick">
                            <i className="fas fa-cloud-upload-alt" />
                            <h5 className="dropzone-msg-title">
                              {t("Drop files here or click to upload.")}
                            </h5>
                            <span className="dropzone-msg-desc">
                              {t(
                                "This is just a demo dropzone. Selected files are not actually uploaded."
                              )}
                            </span>
                          </div>
                        </div>
                        <aside
                          style={{ display: "flex" }}
                          className={FloorContainer}
                        >
                          {floorthumbs}
                        </aside>
                        <span className="acr-form-notice">
                          {t("You can upload up to 15 images for your listing")}
                        </span>
                        <span className="acr-form-notice">
                          {t(
                            "Listing images should be at least 620x480 in dimensions"
                          )}
                        </span>
                      </div>

                      {user ? (
                        disbtn ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <button
                            className="btn-custom"
                            name="submit"
                            onClick={(e) => {
                              submitlistingdata(e);
                            }}
                            disabled={disbtn} // Disable the button while loading
                          >
                            {disbtn ? (
                              <span>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>{" "}
                                {t("Submitting...")}
                              </span>
                            ) : (
                              t("submitListing")
                            )}
                          </button>
                        )
                      ) : null}

                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => navigate("/home")}
                      >
                        <span className="fa fa-reply"></span> Cancel
                      </button>

                      <div>
                        {error === undefined || false ? (
                          ""
                        ) : (
                          <div
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "80%",
                                backgroundColor: "#FF3131",
                                color: "white",
                                padding: "10px 20px 10px 20px",
                                borderRadius: "5px",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                {fieldError
                                  ? " Please Fill Out All Fields"
                                  : ""}
                              </span>
                              <span>
                                {minFileError
                                  ? " Please Select Images for Thumbnail or Gallery"
                                  : ""}
                              </span>
                              <span>
                                {maxFileError
                                  ? "You can select only 5 pictures"
                                  : ""}
                              </span>
                              <button
                                style={{
                                  border: "white 2px solid",
                                  borderRadius: "25px",
                                  width: "35px",
                                  backgroundColor: "#FF3131",
                                  color: "white",
                                  fontSize: "15px",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  setError(undefined);
                                  setFieldError(undefined);
                                  setMinFileError(undefined);
                                  setMaxFileError(undefined);
                                }}
                              >
                                x
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </form>
              </div>
            </Tab.Container>
            {/* Tab Content End */}
          </div>
        </Container>
        <ConditionModal
          acceptCondition={() => {
            setCheckbox(!checkbox);
            setOpen(!open);
          }}
          value={checkbox}
          closeModal={() => setOpen(false)}
          isOpen={open}
        />
        <ConfirmModal
          data={emptyfield}
          isopen={modal}
          setisopen={setModal}
          handleClick={submitData}
        />
      </div>
    </div>
  );
}

export default Content;
