function InfoGraphic() {
    return (
        <div className="container">
            <div className="d-flex justify-center ">
                <img className="w-100" src={require("../../../assets/img/buying and selling process.png")} alt="infographic" />
            </div>
        </div>
    );
}

export default InfoGraphic;