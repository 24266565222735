import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
// import { FaEye } from "react-icons/fa";
// import { FaEyeSlash } from "react-icons/fa";
import { LoginSocialGoogle } from "reactjs-social-login";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { useToast } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Content = () => {
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [verify, setverify] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [code, setCode] = useState("");

  const [isLoading, setIsLoading] = useState(false); // Step 2

  const { t } = useTranslation();
  const toast = useToast();

  const countryCode = [
    "+1", // United States
    "+44", // United Kingdom
    "+92", // Pakistan
    "+91", // India
    "+33", // France
    "+49", // Germany
    "+81", // Japan
    "+86", // China
    "+61", // Australia
    "+34", // Spain
    "+39", // Italy
    "+7", // Russia
    "+82", // South Korea
    "+52", // Mexico
    "+55", // Brazil
    "+971", // United Arab Emirates
    "+966", // Saudi Arabia
    "+968", // Oman
    "+20", // Egypt
    "+27", // South Africa
    "+30", // Greece
    "+31", // Netherlands
    "+32", // Belgium
    "+46", // Sweden
    "+47", // Norway
    "+48", // Poland
    "+54", // Argentina
    "+56", // Chile
    "+57", // Colombia
    "+58", // Venezuela
    "+60", // Malaysia
    "+63", // Philippines
    "+64", // New Zealand
    "+65", // Singapore
    "+66", // Thailand
    "+81", // Japan
    "+82", // South Korea
    "+84", // Vietnam
    "+86", // China
    "+90", // Turkey
    "+91", // India
    "+92", // Pakistan
    "+93", // Afghanistan
    "+94", // Sri Lanka
    "+95", // Myanmar
    "+98", // Iran
    "+212", // Morocco
    "+213", // Algeria
    "+216", // Tunisia
    "+218", // Libya
    "+220", // Gambia
    "+221", // Senegal
    "+222", // Mauritania
    "+223", // Mali
    "+224", // Guinea
    "+225", // Ivory Coast
    "+226", // Burkina Faso
    "+227", // Niger
    "+228", // Togo
    "+229", // Benin
    "+230", // Mauritius
    "+231", // Liberia
    "+232", // Sierra Leone
    "+233", // Ghana
    "+234", // Nigeria
    "+235", // Chad
    "+236", // Central African Republic
    "+237", // Cameroon
    "+238", // Cape Verde
    "+239", // Sao Tome and Principe
    "+240", // Equatorial Guinea
    "+241", // Gabon
    "+242", // Republic of the Congo
    "+243", // Democratic Republic of the Congo
    "+244", // Angola
    "+245", // Guinea-Bissau
    "+246", // British Indian Ocean Territory
    "+247", // Ascension Island
    "+248", // Seychelles
    "+249", // Sudan
    "+250", // Rwanda
    "+251", // Ethiopia
    "+252", // Somalia
    "+253", // Djibouti
    "+254", // Kenya
    "+255", // Tanzania
    "+256", // Uganda
    "+257", // Burundi
    "+258", // Mozambique
    "+260", // Zambia
    "+261", // Madagascar
    "+262", // Reunion
    "+263", // Zimbabwe
    "+264", // Namibia
    "+265", // Malawi
    "+266", // Lesotho
    "+267", // Botswana
    "+268", // Eswatini
    "+269", // Comoros
  ];

  const setUser = (data) => {
    localStorage.setItem("userInfo", JSON.stringify(data));
    window.location.replace("/");
  };
  const initialValues = {
    email: "",
    selectorValue: "",
    phonenumber: "",
    password: "",
    selectorValueCode: "th",
  };
  const validationSchemaLogin = Yup.object().shape({
    selectorValue: Yup.string().required(t("Please Select Option")),
    selectorValueCode: Yup.string().required(t("Please Select Country Code")),
    email: Yup.string()
      .email(t("Invalid email"))
      .required(t(`Email is required`)),
    phonenumber: Yup.string()
      .matches(/^[0-9]+$/, t("Phone number must only contain digits"))
      .min(10, t("Phone number is too short"))
      .max(15, t("Phone number is too long"))
      .required(t("Phone number is required")),

    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/,
        t("Password must contain")
      )
      .min(8, t("Password must be at least 8 characters long"))
      .required(t("Password is required")),
  });

  // const postData =  async(formik) => {
  //   setIsLoading(true);
  //   const { selectorValue, email,  phonenumber, selectorValueCode} = formik?.values;

  //   if (selectorValue === 'email') {

  //     if (!email) {
  //       setIsLoading(false);
  //       return; // Exit the function early
  //     }
  //   } else if (selectorValue === 'phonenumber') {

  //     if (!phonenumber  || !selectorValueCode) {
  //       setIsLoading(false);
  //       return;
  //     }
  //   }
  //   let bodyContent;
  // if (formik.values.selectorValue === "mobile") {
  //   // If mobile is selected, include both phone and email in the body
  //   bodyContent = {
  //     phone: formik.values.selectorValueCode+formik.values.phonenumber,
  //     password:formik.values.password
  //   };
  // } else if (formik.values.selectorValue === 'email') {
  //   // If email is selected, include only email in the body
  //   bodyContent = {
  //     email: formik.values.email,
  //     password:formik.values.password

  //   };
  // }

  //   await fetch(`${BASE_URL}/login1`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       mode: "no-cors",
  //     },
  //     body: JSON.stringify(bodyContent),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.Msg === "login") {
  //         setUser(data);
  //       } else {
  //         setError(true);
  //         setErrorMsg(data.Msg);
  //       }
  //       setIsLoading(false);
  //     });
  // };

  const postData = async (formik) => {
    setIsLoading(true);
    const { selectorValue, email, phonenumber, selectorValueCode, password } =
      formik?.values;

    // Check for selectorValue and corresponding fields
    if (!selectorValue) {
      setErrorMsg("Please select an option");
      setIsLoading(false);
      return;
    }

    if (selectorValue === "email") {
      if (!email) {
        setIsLoading(false);
        return;
      }
    } else if (selectorValue === "mobile") {
      if (!phonenumber || !selectorValueCode) {
      
        setIsLoading(false);
        return;
      }
    }

    // Prepare body content based on selectorValue
    let bodyContent = {};
    if (selectorValue === "mobile") {
      bodyContent = {
        phone: `${selectorValueCode}${phonenumber}`,
        password: password,
      };
    } else if (selectorValue === "email") {
      bodyContent = {
        email: email,
        password: password,
      };
    }
    // Check if bodyContent has required fields and selectorValue is present
    if (bodyContent && selectorValue) {
      try {
        const response = await fetch(`${BASE_URL}/login1`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyContent),
        });
        const data = await response.json();
        if (data.Msg === "login") {
          setUser(data);
        } else {
          setError(true);
          setErrorMsg(data.Msg);
        }
      } catch (error) {
        setError(true);
        setErrorMsg("An error occurred");
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const images = [
    {
      img: "assets/img/coming-soon/1.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/2.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/3.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
  ];
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    dotsClass: "d-flex slick-dots",
  };

  const [selectorValue, setSelectorValue] = useState("");
  const [selectorValueCode, setCountryCode] = useState("");
  const handleChange = (e) => {
    setSelectorValue(e.target.value);
  };

  const handleChangeCode = (e, formik) => {
    const { value } = e.target;

    formik.setFieldValue("selectorValueCode", value);
  };

  // axios package install karein

  const getuserdata = () => {
    const code =
      "4/0eaVSHC56XKm_up7hLkY7QufJiwks2FJTYrFs-F4ZCPBygQcSGUHk3AGNzRUPub23ydmq0";

    axios
      .post("https://oauth2.googleapis.com/token", {
        client_id: "YOUR_CLIENT_ID",
        client_secret: "YOUR_CLIENT_SECRET",
        code: code,
        grant_type: "authorization_code",
        redirect_uri: "YOUR_REDIRECT_URI",
      })
      .then((res) => {
        const access_token = res.data.access_token;
        const id_token = res.data.id_token;

        axios
          .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          .then((res) => {})
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const sendOtp = async (formik) => {
    const { selectorValue, email, phonenumber, selectorValueCode, password } =
      formik?.values;
      if (!selectorValue) {
        setErrorMsg("Please select an option");
        setIsLoading(false);
        return;
      }
  
      if (selectorValue === "email") {
        if (!email) {
          setIsLoading(false);
          return;
        }
      } else if (selectorValue === "mobile") {
        if (!phonenumber || !selectorValueCode) {
          setIsLoading(false);
          return;
        }
      }
  
      // Prepare body content based on selectorValue
      let bodyContent = {};
      if (selectorValue === "mobile") {
        bodyContent = {
          phone: `${selectorValueCode}${phonenumber}`,
        };
      } else if (selectorValue === "email") {
        bodyContent = {
          email: email,
        };
      }
      if (bodyContent && selectorValue) {
        try {
          const response = await fetch(`${BASE_URL}/sendOtp`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyContent),
          });
          const data = await response.json();
          if(data){
            setError(false)
            setverify(true)
          }
          
        } catch (error) {
          setError(true);
          setErrorMsg("An error occurred");
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
     
  };
  const userData = (data) => {
    localStorage.setItem("userInfo", JSON.stringify(data));
    window.location.replace("/");
  };
  const postDataVerify = async () => {
    setIsLoading(true); // Start loading indicator

    if (!code || code === undefined) {
      toast({
        title: "Error",
        description: "Enter code first.",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }

    await axios
      .post(`${BASE_URL}/register-verify`, {
        code: code,
      })
      .then((res) => {
        res.data.Msg === "register" ? userData(res.data) : setError(true);
        setErrorMsg(res.data.Msg);
        setIsLoading(false);
      });
  };
  return (
    <div className="acr-auth-container ">
      <div className="acr-auth-content ">
      {verify ? (
          <form
            className="py-1"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          // enctype="multipart/form-data"
          // method="post"
          >
            <div className="auth-text">
              <h3>{t("createAnAcresAccount")}</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
              </p>
            </div>
             
            <div className="form-group">
              <label>{t("Verification Code")}</label>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="form-control form-control-light"
                placeholder={t("Enter your verification code")}
                name="code"
              />
            </div>
            <button
              type="Submit"
              className="btn-custom secondary btn-block"
              onClick={() => postDataVerify()}
              disabled={code === "" ? true :false||isLoading}
            >
              {isLoading ? (
                <div className="spinner-border" role="status">
                </div>
              ) : (
                t("Verify")
              )}

            </button>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {error ? (
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "80%",
                    backgroundColor: "#FF3131",
                    color: "white",
                    padding: "10px 20px 10px 20px",
                    borderRadius: "5px",
                    alignItems: "center",
                  }}
                >
                  <span>{error ? `${t(errorMsg)}` : ""}</span>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      border: "white 2px solid",
                      borderRadius: "30px",
                      width: "40px",
                      backgroundColor: "#FF3131",
                      height: "40px",
                    }}
                    onClick={() => {
                      setError(false);
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        alignItems: "center",
                        marginTop: "3px",
                      }}
                    >
                      x
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <p className="text-center mb-0">
              {t("alreadyHaveAccount")} <Link to="/login">{t("login")}</Link>{" "}
            </p>
          </form>
        ):(
        
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaLogin}
          onSubmit={postData}
          validateOnChange
        >
          {(formik) => {
            return (
              <Form>
                <div className="auth-text">
                  <h3>{t("logIntoAcres")}</h3>
                </div>

                <div>
                  <div className="form-group">
                    <label>{t("selectEmailOrPhone")}</label>
                    <Field
                      as="select" // Use "as" prop to specify the HTML element type
                      className="form-control form-control-light"
                      name="selectorValue"
                    >
                      <option value="" label={t("selectEmailOrPhoneNumber")} />
                      <option value="email" label={t("emailAddress")} />
                      <option value="mobile" label={t("mobileNumber")} />
                    </Field>
                    <ErrorMessage
                      name="selectorValue"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                  {formik.values.selectorValue === "email" && (
                    <div className="form-group">
                      <label>{t("emailAddress")}</label>

                      <Field
                        type="text"
                        // value={email}
                        // onChange={(e) => setEmail(e.target.value)}
                        className="form-control form-control-light"
                        placeholder={t("emailAddress")}
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  )}
                  {formik.values.selectorValue === "mobile" && (
                    <div className="form-group">
                      <label>{t("mobileNumber")}</label>
                      {/* <div style={{ display: "flex" }}>
                        <Field
                          as="select"
                          // value={formik.values.selectorValueCode}
                          onChange={(e) => handleChangeCode(e, formik)}
                          className="form-controlCode form-control-light"
                          name="selectorValueCode"
                        >
                          {countryCode.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </Field>
                        <Field
                          type="text"
                          className="form-control form-control-light"
                          placeholder={t("mobileNumber")}
                          name="phonenumber"
                        />
                      </div> */}
                       <PhoneInput
                              country={formik.values.selectorValueCode || "th"} // Default to Pakistan's country code
                              value={formik.values.phonenumber}
                              onChange={(value, country) => {
                                formik.setFieldValue("phonenumber", value);
                                formik.setFieldValue(
                                  "selectorValueCode",
                                  country.dialCode
                                );
                              }}
                              inputStyle={{ width: "100%",height:"45px" }}
                            />
                      <ErrorMessage
                        name="phonenumber"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label>{t("password")}</label>
                    <Field
                      type="password"
                      // value={password}
                      // onChange={(e) => setPassword(e.target.value)}
                      className="form-control form-control-light"
                      placeholder={t("password")}
                      name="password"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <Link to="/forgotpassword" className="forgot-password">
                      {t("forgotPassword")}
                    </Link>
                    <br />
                    <div
                      onClick={()=>sendOtp(formik)}
                      style={{ cursor: "pointer" }}
                      className="forgot-password"
                    >
                      {errorMsg === "Need to verify first"
                        ? "Resend Code."
                        : ""}
                    </div>
                  </div>
                  <button
                    type="submit"
                    style={{ width: "30%" }}
                    className="btn-custom secondary btn-block"
                    onClick={() => postData(formik)}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="spinner-border" role="status"></div>
                    ) : (
                      t("login")
                    )}
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {error ? (
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "80%",
                        backgroundColor: "#FF3131",
                        color: "white",
                        padding: "10px 20px 10px 20px",
                        borderRadius: "5px",
                        alignItems: "center",
                      }}
                    >
                      <span>{error ? `${t(errorMsg)}` : ""}</span>
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          border: "white 2px solid",
                          borderRadius: "30px",
                          width: "40px",
                          backgroundColor: "#FF3131",
                          height: "40px",
                        }}
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <p
                          style={{
                            color: "white",
                            alignItems: "center",
                            marginTop: "3px",
                          }}
                        >
                          x
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* Social-login */}
                {/* <div className="auth-seperator">
                  <span>{t("OR")}</span>
                </div>
                <div>
                  <div className="social-login">
                    <button  type="button" className="acr-social-login facebook">
                      <i className="fab fa-facebook-f" /> {t("continueWithFacebook")}
                    </button>
                    <LoginSocialGoogle 
                    client_id='89687166826-ru3hecfiibj4dp11dn5m3emps00slg56.apps.googleusercontent.com' 
                    discoveryDocs='claims_supported'
                    access_type='offline'
                    onResolve={({provider , data})=>{
                      // console.log(provider , data)
                      if(data){
                        setUserDetails(data) 
                        getuserdata()
                      }
                    }}
                    onReject={({err})=>{
                      console.log(err)
                    }}
                    >
                    <button  type="button"  className="acr-social-login google">
                      <i className="fab fa-google" /> {t("continueWithGoogle")}
                    </button>
                    </LoginSocialGoogle>
                  </div>
                </div> */}
                {/* Social-login */}
                <p className="text-center mt-3 mb-0">
                  {t("createAnAcresAccount")}{" "}
                  <Link to="/register">{t("register")}</Link>{" "}
                </p>
              </Form>
            );
          }}
        </Formik>
        )}
      </div>
      <div className="acr-auth-bg">
        <Slider className="acr-auth-bg-slider acr-cs-bg-slider" {...settings}>
          {images.map((item, i) => (
            <div key={i}>
              <div
                className="acr-cs-bg-item bg-cover bg-center"
                style={{
                  backgroundImage:
                    "url(" + process.env.PUBLIC_URL + "/" + item.img + ")",
                }}
              >
                {/* <div className="acr-auth-quote">
                  <h6>{item.title}</h6>
                  <p>{item.text}</p>
                </div> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Content;
