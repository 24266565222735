import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown, NavLink } from "react-bootstrap";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import defaultpic from "../../../assets/DefaultPic/profile.png";
import BASE_URL from "../../../baseUrl";

const gallerytip = () => <Tooltip>Gallery</Tooltip>;
const bedstip = () => <Tooltip>Beds</Tooltip>;
const bathstip = () => <Tooltip>Bathrooms</Tooltip>;
const areatip = () => <Tooltip>Square Feet</Tooltip>;

const Toplistings = () => {
  const customeSlider = useRef();
  const { t } = useTranslation();

  const [listing, setListing] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);

  const SubmitlistingData = async () => {
    // const response = await fetch(`${BASE_URL}/submitlisting/lastsubmit`);
    const response = await fetch(`${BASE_URL}/admin/topList`);
    const data = await response.json();
    setListing(data.result);
  };

  useEffect(() => {
    SubmitlistingData();
  }, []);

  const next = () => {
    if (customeSlider.current) {
      customeSlider.current.slickNext();
    }
  };

  const previous = () => {
    if (customeSlider.current) {
      customeSlider.current.slickPrev();
    }
  };

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     const width = window.innerWidth;
  //     if (width < 768) {
  //       setSlidesToShow(1);
  //     } else if (width < 992) {
  //       setSlidesToShow(2);
  //     } else {
  //       setSlidesToShow(3);
  //     }
  //   };

  //   handleResize();
  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  // const slidesToShows = listing?.length < 3 ? listing?.length : 3;

  // const settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: slidesToShows,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   beforeChange: handleBeforeChange,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: listing?.length < 2 ? listing?.length : 3,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: listing?.length < 2 ? listing?.length : 2,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //         infinite: false,
  //       },
  //     },
  //   ],
  // };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: handleBeforeChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: false,
        },
      },
    ],
  };

  return (
    <div className="section sectionlisting">
      <div className="container top-listings">
        <div className="acr-arrows" style={{ left: "40px", bottom: "-40px" }}>
          <button
            className="slider-prev fas fa-arrow-left slick-arrow"
            onClick={previous}
            disabled={currentSlide === 0}
          />
          <button
            className="slider-next fas fa-arrow-right slick-arrow"
            onClick={next}
            disabled={currentSlide >= listing.length - slidesToShow}
          />
        </div>
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">{t("trending")}</h5>
          <h2 className="title">{t("ourTopListings")}</h2>
        </div>
        <Slider
          className="top-listings-slider"
          ref={customeSlider}
          {...settings}
        >
          {listing.length == 0 ? (
            <p className="text-center my-4 text-xl font-medium">
              {t("No top list available")}
            </p>
          ) : (
            listing?.map((item, i) => (
              <div key={i}>
                <div
                  className="listing listing-list myBestdealofMonth justify-center"
                  style={{
                    margin: "0.5rem",
                    height: "450px",
                    border: "1px solid black",
                  }}
                >
                  <div
                    className="listing-body bg-light "
                    style={{ width: "100%" }}
                  >
                    <h5 className="listing-title mb-3 px-2 pt-3">
                      <Link
                        to={`/listing-details-v1/${item?._id}`}
                        title={item.BasicInformation?.name}
                      >
                        {item.BasicInformation?.name}
                      </Link>
                    </h5>
                    <div className="listing-author mb-3 px-2">
                      {/* <img src={item.author?.pic || defaultpic} alt="author" /> */}
                      <img src={item.Gallery?.file?.[0] || defaultpic} alt="author" className="h-[40px] w-[70px] rounded-full"/>
                      <div className="listing-author-body">
                        <p>
                          <Link to="#">{item.author?.name}</Link>
                        </p>
                        <span className="listing-date">
                          {item?.createdAt?.split("T")[0]}
                        </span>
                      </div>
                    </div>
                    <span className="listing-price mb-3 px-2">
                      {item.BasicInformation?.listingType === "Rent" ? (
                        <>
                          {item.BasicInformation.currency +
                            (item.BasicInformation.rentalPrice?.toString()
                              .length > 10
                              ? item.BasicInformation.rentalPrice
                                  .toString()
                                  .slice(0, 10) + "..."
                              : Number(item.BasicInformation.rentalPrice).toLocaleString())}
                        </>
                      ) : (
                        <>
                          {item.BasicInformation.currency +
                            (item.BasicInformation.price?.toString().length > 10
                              ? item.BasicInformation.price
                                  .toString()
                                  .slice(0, 10) + "..."
                              : Number(item.BasicInformation.price).toLocaleString())}
                        </>
                      )}
                    </span>
                    <p className="listing-text">
                      {item.BasicInformation?.description?.length > 70
                        ? item.BasicInformation?.description.slice(0, 50) +
                          "..."
                        : item.BasicInformation?.description}
                    </p>
                    <div className="acr-listing-icons mb-3 px-2" style={{height:"50px",fontSize:'12px'}}>
                      <OverlayTrigger overlay={bedstip}>
                        <div className="acr-listing-icon">
                          <i className="flaticon-bedroom" />
                          <span className="acr-listing-icon-value">
                            {item.Details?.beds?item.Details?.beds
                              : item.Details?.beds}
                          </span>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger overlay={bathstip}>
                        <div className="acr-listing-icon">
                          <i className="flaticon-bathroom" />
                          <span className="acr-listing-icon-value">
                            {item.Details?.bathrooms?item.Details?.bathrooms
                              : item.Details?.bathrooms}
                          </span>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger overlay={areatip}>
                        <div className="acr-listing-icon">
                          <i className="flaticon-ruler" />
                          <span className="acr-listing-icon-value">
                            {item.BasicInformation?.space?item.BasicInformation?.space
                              : item.BasicInformation?.space}{" "}
                            SQM
                          </span>
                        </div>
                      </OverlayTrigger>
                    </div>

                    <div className="flex justify-between items-center flex-wrap mb-3 px-2">
                      <div>
                        <span
                          className="acr-listing-icon-value"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          {item.Location.country === "undefined"
                            ? ""
                            : item?.Location?.country ?"Country :" + item.Location?.country:""}
                        </span>
                      </div>
                      <div>
                        <span
                          className="acr-listing-icon-value"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          {item?.Location?.provice === "undefined" ||item?.Location?.provice === ""
                            ? ""
                            : item?.Location?.provice? "Province :" + item?.Location?.provice:''}
                            {''}
                          {item?.Location?.city === "undefined" ||item?.Location.city === ""
                            ? ""
                            : item?.Location?.city?  item?.Location?.city:''}
                        </span>
                      </div>
                    </div>
                    <div className="listing-gallery-wrapper mb-3 px-2">
                      <Link
                        to={`/listing-details-v1/${item._id}`}
                        className="btn-custom btn-sm secondary mb-2"
                      >
                        {t("viewDetails")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Slider>
      </div>
    </div>
  );
};

export default Toplistings;
