import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/ThinkToKnow/RetireContent";
import Download from "../layouts/App";
import BsInfo from "../sections/services/Bsinfo";
import { useTranslation } from "react-i18next";

const Retire = () => {
  const { t } = useTranslation();

  let data = {
    title: t("How to retire in Thailand"),
    para: t("Person"),
    picture: "/assets/img/listings-list/Retire.jpg",
  };
  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | How to retire in Thailand</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("How to retire in Thailand") }} />
      <BsInfo data={data} />

      {/* Inserted information about obtaining a non-immigrant visa */}
      <div className="container">
        <div className="section-title-wrap mr-lg-30">
          <h3 className="subtitle">{t("Step1")}</h3>
          <h5 className="text-danger ms-4">{t("Requirements")}</h5>
          <ul className=" ms-5 h6">
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Passport")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Accommodation")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Bank Statement")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Health Insurance")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Nationality Residence")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Application Location")}
            </li>
          </ul>
        </div>
      </div>
      <div className="container mt-5">
        <div className="section-title-wrap mr-lg-30">
          <h3 className="subtitle">{t("Step2")}</h3>
          <h5 className="text-danger ms-4">
            {t("nonImmigrantOExtensionRequirements")}
          </h5>
          <ul className="ms-5 h6">
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Must Be 50 Years Old")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Passports")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Non Immigrant Visa")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Tm 30 Receipt")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Proof Of Meeting Financial Requirements")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Income Certificate")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Thai Bank Book")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Letter From Thai Bank")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Photos")}
            </li>
            <li style={{ listStyle: "number" }} className="text-muted mb-3">
              {t("Proof Of Permanent Accommodation")}
            </li>
          </ul>
          <h5 className="text-success ms-2">
            {t("Medical Certificate And Criminal Record Certificate")}
          </h5>
        </div>
      </div>

      <div className="container mt-5 mb-5">
        <div className="section-title-wrap mr-lg-30">
          <h3 className="subtitle">{t("Step3")}</h3>

          <h5 className="text-muted ms-4">
            {t("RE-ENTRY PERMIT")}
          </h5>
          <h6>{t('Extension of Stay and Re-Entry Permit')}</h6>
          <ul>
            <li>{t('E_1')}</li>
            <li>{t('E_2')}</li>
            <li>{t('E_3')}</li>
          </ul>
          <h6>{t('Note')}</h6>
          <p>{t('v_1')}</p>
          <p>{t('v_2')}</p>
          <p>{t('v_3')}</p>
        </div>
        <h6>{t('* For enquiries about the visa application, please contact the visa section VIA (EMAIL ONLY)')}</h6>
      </div>
      <div className="container mt-5 mb-5">
        <div className="section-title-wrap mr-lg-30">
          <h3 className="subtitle">{t("Step4")}</h3>
          <h6 className="subtitle">
            {t("FOREIGNERS STAYING IN THAILAND MORE THAN 90 DAYS")}
          </h6>
          <span>
            {t(
              "IMPORTANT INFORMATION FOR FOREIGNERS STAYING IN THAILAND MORE THAN 90 DAYS"
            )}
          </span>
          <h6 className="subtitle">
            {t("Notification of staying in the Kingdom over 90 days")}
          </h6>
          <span>{t("notifypara")}</span>
          <h6 className="subtitle">{t('The place')}</h6>
          <p>{t("place1")}</p>
          <p>{t("place2")}</p>
          <p>{t("place3")}</p>
          {/* – Download Thai Immigration Form */}
          <h6>{t("The Fine : In case of not notifying.")}</h6>
          <p>{t("finepara")}</p>
          <p>{t("finepara2")}</p>
          <h6>{t("Procedure and notification")}</h6>
          <p>{t('1. The foreigner makes the notification in person, or')}</p>
          <p>
            {t('2. The foreigner authorizes another person to make the notification,or')}
          </p>
          <p>
           {t('3. The foreigner authorizes another person to make the notification,or')}
          </p>
          <p>
           {t(' 4. The notification must be made within 7 days before or after the period of 90 days expires.')}
          </p>
          <p>
            {t('5. The first application for extension of stay by the foreigner is  equivalent to the notification of staying in the Kingdom over 90 days')}
          </p>
          <h6>{t('Notification by registered mail')}</h6>
          <p> {t('1. Copy of all passport pages (up to the latest arrival stamp in the Kingdom or latest visa stamp)')}
          </p>
          <p>{t('2. Copy of arrival/departure card TM. 6 (front and back)')}</p>
          <p>{t('3. Previous notifications of staying over 90 days (if any)')}</p>
          <p>{t('4. Filled in and signed notification form TM. 47')}</p>
          <p>
            {t("5")}
          </p>
          <p>
           {t(`6`)}
          </p>
          <p>
           {t(`90days`)}
          </p>
          <h6>{t('Note')}</h6>
          <ul>
            <li>{t('Form TM.47 (Download from www.immigration.go.th )')}</li>
            <li>
              {t(`The notification of staying in the Kingdom over 90 days is in no way equivalent to a visa extension`)}
            </li>
            <li>
              {t(`notepara3`)}
            </li>
            <li>
              {t(`foreignleave`)}
            </li>
            <li>
             {t(`For detail`)}
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="container mt-5 mb-5">
      <div className="section-title-wrap mr-lg-30">
        <h3 className="subtitle">
          {t('Summary')}
        </h3>

        <h5 className="text-muted ms-4">
          {t('Summary Description')}
        </h5>
      </div>
    </div> */}
      <br />
      <br />
      <div className="mt-5">
        <Download />
      </div>
      <Footer />
    </Fragment>
  );
};

export default Retire;
