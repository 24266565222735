import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import defaultpic from "../../../assets/DefaultPic/profile.png";
import BASE_URL from "../../../baseUrl";

const gallerytip = () => <Tooltip>Gallery</Tooltip>;
const bedstip = () => <Tooltip>Beds</Tooltip>;
const bathstip = () => <Tooltip>Bathrooms</Tooltip>;
const areatip = () => <Tooltip>Square Feet</Tooltip>;

const Latestblog = () => {
  const [state, setState] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const customeSlider = useRef();
  const { t } = useTranslation();

  const SubmitlistingData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/admin/bestDeals`);
      const data = await response.json();
      setState(data?.result || []);
    } catch (error) {
      console.error("Error fetching listing data:", error);
    }
  };

  useEffect(() => {
    SubmitlistingData();
  }, []);

  const next = () => {
    if (customeSlider.current) {
      customeSlider.current.slickNext();
    }
  };

  const previous = () => {
    if (customeSlider.current) {
      customeSlider.current.slickPrev();
    }
  };

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesToShow(1);
      } else if (width < 992) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: handleBeforeChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: false,
        },
      },
    ],
  };

  return (
    <div className="section section-padding light-bg">
      <div className="container">
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">{t("latestNews")}</h5>
          <h2 className="title">{t("bestDealOfTheMonth")}</h2>
        </div>

        <Slider
          className="top-listings-slider"
          ref={customeSlider}
          {...settings}
        >
          {state.length === 0 ? (
            <p className="text-center my-4 text-xl font-medium">
              {t("No Deals Available")}
            </p>
          ) : (
            state.map((res, key) => {
              const basicInformation = res?.BasicInformation;
              const details = res?.Details;
              const gallery = res?.Gallery;
              const author = res?.Author ? res?.Author : res?.Admin;
              return (
                <div key={key}>
                  <div
                    className="listing listing-list myBestdealofMonth"
                    style={{ margin: "0.5rem", height: "550px" }}
                  >
                    <div
                      className="listing-thumbnail listingMainImage2"
                      style={{ width: "80%" }}
                    >
                      <Link to={`/listing-details-v1/${res._id}`}>
                        <img
                          src={gallery?.file[0] || defaultpic}
                          alt="listing"
                          style={{ width: "100%", height: "100%" }}
                          className="listingMainImage2"
                        />
                      </Link>
                      <div className="listing-badges classBadge">
                        {basicInformation?.status
                          ?.split(", ")
                          .includes("For Sale") && (
                          // <span className="listing-badge sale">{t("On Sale")}</span>
                          <></>
                        )}
                        {basicInformation?.status
                          ?.split(", ")
                          .includes("Rental") && (
                          <span className="listing-badge rent">
                            {t("Rental")}
                          </span>
                        )}
                      </div>
                      <div className="listing-controls" />
                    </div>
                    <div className="listing-body" style={{ width: "100%" }}>
                      <div className="listing-author">
                        <img src={author?.pic || defaultpic} alt="author" />
                        <div className="listing-author-body">
                          <p>
                            <Link to="#">{author?.name}</Link>
                          </p>
                          <span className="listing-date">
                            {res?.createdAt?.split("T")[0]}
                          </span>
                        </div>
                      </div>
                      <h5 className="listing-title">
                        <Link
                          to={`/listing-details-v1/${res?._id}`}
                          title={basicInformation?.name}
                        >
                          {basicInformation?.name}
                        </Link>
                      </h5>
                      <span className="listing-price">
                        {basicInformation?.listingType === "Rent" ? (
                          <>
                            {basicInformation.currency +
                              (basicInformation.rentalPrice?.toString().length >
                              10
                                ? basicInformation.rentalPrice
                                    .toString()
                                    .slice(0, 10) + "..."
                                : Number(basicInformation?.rentalPrice).toLocaleString())}
                          </>
                        ) : (
                          <>
                            {basicInformation.currency +
                              (basicInformation.price?.toString().length > 10
                                ? basicInformation.price
                                    .toString()
                                    .slice(0, 10) + "..."
                                : Number(basicInformation.price).toLocaleString())}
                          </>
                        )}
                      </span>
                      <p className="listing-text">
                        {basicInformation?.description?.length > 70
                          ? basicInformation?.description.slice(0, 70) + "..."
                          : basicInformation?.description}
                      </p>
                      <div className="acr-listing-icons">
                        <OverlayTrigger overlay={bedstip}>
                          <div className="acr-listing-icon">
                            <i className="flaticon-bedroom" />
                            <span className="acr-listing-icon-value">
                              {details?.beds?.toString().length > 10
                                ? details.beds.toString().slice(0, 10) + "..."
                                : details?.beds}
                            </span>
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={bathstip}>
                          <div className="acr-listing-icon">
                            <i className="flaticon-bathroom" />
                            <span className="acr-listing-icon-value">
                              {details?.bathrooms?.toString().length > 10
                                ? details.bathrooms.toString().slice(0, 10) +
                                  "..."
                                : details?.bathrooms}
                            </span>
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={areatip}>
                          <div className="acr-listing-icon">
                            <i className="flaticon-ruler" />
                            <span className="acr-listing-icon-value">
                              {basicInformation?.space?.toString().length > 10
                                ? basicInformation.space
                                    .toString()
                                    .slice(0, 10) + "..."
                                : basicInformation?.space}{" "}
                              SQM
                            </span>
                          </div>
                        </OverlayTrigger>
                      </div>

                      <div
                        className="flex justify-between items-center"
                        style={{ height: "80px" }}
                      >
                        <div>
                          <span
                            className="acr-listing-icon-value"
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            {res.Location.country
                              ? res.Location.country.length > 10
                                ? "Country: " +
                                  res.Location.country.slice(0, 10) +
                                  "...."
                                : "Country: " + res.Location.country
                              : null}
                          </span>
                        </div>
                        <div>
                          <span
                            className="acr-listing-icon-value"
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            {res?.Location?.provice === "undefined" ||
                            res?.Location?.provice === ""
                              ? ""
                              : "Province:" + res?.Location?.provice}
                          </span>
                        </div>
                      </div>
                      <div className="listing-gallery-wrapper">
                        <Link
                          to={`/listing-details-v1/${res._id}`}
                          className="btn-custom btn-sm secondary"
                        >
                          {t("viewDetails")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </Slider>
        <div className="acr-arrows-top-listing">
          <button
            className="slider-prev-top-listing fas fa-arrow-left slick-arrow-top-listing"
            onClick={previous}
            disabled={currentSlide === 0}
          />
          <button
            className="slider-next-top-listing fas fa-arrow-right slick-arrow-top-listing"
            onClick={next}
            disabled={currentSlide >= state.length - slidesToShow}
          />
        </div>
      </div>
    </div>
  );
};

export default Latestblog;
