import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Homefour from "./components/pages/Homefour";
import Chat from "./components/pages/Chat";
import Services from "./components/pages/Services";
import About from "./components/pages/About";
import Retirement from "./components/pages/Agentarchive";
import Submitlisting from "./components/pages/Submitlisting";

import Login from "./components/pages/Login";
import Register from "./components/pages/Register";

import Pricing from "./components/pages/Pricing";
import Sell from "./components/pages/Sell";
import Rent from "./components/pages/Rent";

import Distress from "./components/pages/Distress";
import Planing from "./components/pages/Planing";
import Modification from "./components/pages/Modification";
import Management from "./components/pages/Management";
import Planner from "./components/pages/Planner";

import Property from "./components/pages/Property";
import Terms from "./components/pages/Terms";
import Estate from "./components/pages/Estate";
import Retire from "./components/pages/Retire";

import Mortgage from "./components/pages/Mortgage";
import Invest from "./components/pages/Invest";
import Loans from "./components/pages/Loans";

import Listing from "./components/pages/Listingmap";
import Contact from "./components/pages/Contact";
import ListingDetailsOne from "./components/pages/Listingdetailsone";
import "./index.css";
import PropertyListing from "./components/pages/PropertyListing";
import View from "./components/sections/view-all/View-All";

// import FooterContent from "./components/pages/AdminFooterContent";

import Termsandcond from "./components/pages/Termsandconditions";
import Privacypolicy from "./components/pages/PrivacyPolicy";
import Refundpolicy from "./components/pages/Refundpolicy";
import Cookiepolicy from "./components/pages/CookiePolicy";
import Sellproperty from "./components/pages/Mortgage";
import Listingwrapper from "./components/sections/listing-details-v1/Listingwrapper";

import Bloggrid from "./components/pages/Bloggrid";
import Bloglist from "./components/pages/Bloglist";

import Hometwo from "./components/pages/Hometwo";
// import Adminpricerange from "./components/pages/Adminpricerange";
// import AdminPriceRangeCreate from "./components/pages/AdminPriceRangeCreate";
// import AdminSeo from "./components/pages/AdminSeo";
// import AdminHeaderReference from "./components/pages/AdminReference";
// import AdminBannerPop from "./components/pages/AdminBannerPop";
import Complain from "./components/pages/Complain";
import AgentCreate from "./components/pages/AgentCreate";
import SellForm from "./components/pages/SellForm";
import { BackTop, FloatButton } from "antd";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/sections/Language/Language";
import Forgot from "./components/sections/forgotPassword/forgot";
import { LanguageProvider } from "./Context/LanguageContext";
import GetHome from "./components/pages/GetHome";
import Agents from "./components/pages/Agents/Agents";
import { BiVerticalTop } from "react-icons/bi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Catgory_thing_to_know from "./components/pages/Category_thing_toknow";
import Job from "./components/pages/Job";
import ThailandLaws from "./components/pages/ThailandLaws";

const App = () => {
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  // Function to scroll to top
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Event listener for scroll
  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);


  return (
    <LanguageProvider>
      <I18nextProvider i18n={i18n}>
        <div className="App main_class">
          {/* {userInfo ? (
        <Routes>
          <Route path="/home" element={<Homefour />} />
          <Route path="/hometwo" element={<Hometwo />} />
         

          <Route path="/" element={<Homefour />} />
          <Route path="/listingwrapper" element={<Listingwrapper />} />
          <Route path="/submit-listing" element={<Submitlisting />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/agent" element={<Retirement />} />
          <Route path="/services" element={<Services />} />
          <Route path="/buy" element={<Pricing />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/rent" element={<Rent />} />

          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<Forgot />} />
          <Route exact
            path="/listing-details-v1/:id"
            element={<ListingDetailsOne />}
          />
          <Route path="/property/:type" element={<PropertyListing />} />
          <Route path="/sell/:type" element={<PropertyListing />} />
          <Route path="/rent/:type" element={<PropertyListing />} />
          <Route path="/view-all" element={<View />} />

          <Route path="/Distress" element={<Distress />} />
          <Route path="/Planing" element={<Planing />} />
          <Route path="/Modification" element={<Modification />} />
          <Route path="/Management" element={<Management />} />
          <Route path="/Planner" element={<Planner />} />
          <Route path="/sellproperty" element={<Sellproperty />} />
          //here is sublist pages for Thing to Know   
          <Route path="/Property" element={<Property />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Retire" element={<Retire />} />
          <Route path="/Termsandcond" element={<Termsandcond />} />
          <Route path="/Complain" element={<Complain />} />
          <Route path="/AgentCreate" element={<AgentCreate />} />
          <Route path="/SellForm" element={<SellForm />} />
          <Route path="/refundpolicy" element={<Refundpolicy />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/cookiepolicy" element={<Cookiepolicy />} />
          <Route path="/Estate" element={<Estate />} />

          <Route path="/Mortgage" element={<Mortgage />} />
          <Route path="/Invest" element={<Invest />} />
          <Route path="/Loans" element={<Loans />} />
          <Route path="/listing-map" element={<Listing />} />
          <Route path="/blog-news" element={<Bloggrid />} />
          <Route path="/blog" element={<Bloglist />} />
          <Route path="*" element={<div>Error 404 Page not found.</div>} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Homefour />} />
          <Route path="/home" element={<Homefour />} />
          <Route path="/listingwrapper" element={<Listingwrapper />} />
          <Route path="/submit-listing" element={<Submitlisting />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/agent" element={<Retirement />} />
          <Route path="/services" element={<Services />} />
          <Route path="/buy" element={<Pricing />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/rent" element={<Rent />} />

          <Route path="/register" element={<Register />} />
          <Route path="/register/:id" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/chat" element={<Chat />} />
          <Route exact
            path="/listing-details-v1/:id"
            element={<ListingDetailsOne />}
          />
          <Route path="/property/:type" element={<PropertyListing />} />
          <Route path="/sell/:type" element={<PropertyListing />} />
          <Route path="/rent/:type" element={<PropertyListing />} />
          <Route path="/view-all" element={<View />} />

          <Route path="/Distress" element={<Distress />} />
          <Route path="/Planing" element={<Planing />} />
          <Route path="/Modification" element={<Modification />} />
          <Route path="/Management" element={<Management />} />
          <Route path="/Planner" element={<Planner />} />
          <Route path="/sellproperty" element={<Sellproperty />} />
          <Route path="/Property" element={<Property />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Termsandcond" element={<Termsandcond />} />
          <Route path="/Complain" element={<Complain />} />
          <Route path="/AgentCreate" element={<AgentCreate />} />
          <Route path="/SellForm" element={<SellForm />} />
          <Route path="/refundpolicy" element={<Refundpolicy />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/cookiepolicy" element={<Cookiepolicy />} />
          //here is sublist pages for Thing to Know   
          <Route path="/Estate" element={<Estate />} />
          <Route path="/Retire" element={<Retire />} />

          <Route path="/Mortgage" element={<Mortgage />} />
          <Route path="/Invest" element={<Invest />} />
          <Route path="/Loans" element={<Loans />} />
          <Route path="/listing-map" element={<Listing />} />
          <Route path="/blog-news" element={<Bloggrid />} />
          <Route path="/forgotpassword" element={<Forgot />} />

          <Route path="*" element={<div>Error 404 Page not found</div>} />
        </Routes>

      )} */}

          <Routes>
            <Route path="/home" element={<Homefour />} />
            <Route path="/hometwo" element={<Hometwo />} />
            <Route path="/" element={<Homefour />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/listingwrapper" element={<Listingwrapper />} />
            <Route path="/submit-listing" element={<Submitlisting />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/agent" element={<Retirement />} />
            <Route path="/services" element={<Services />} />
            <Route path="/buy" element={<Pricing />} />

            <Route path="/sell/*" element={<Sell />} />
            <Route path="/sell/gethome" element={<GetHome />} />
            <Route path="/sell/agent" element={<Agents />} />

            <Route path="/rent" element={<Rent />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<Forgot />} />
            <Route
              exact
              path="/listing-details-v1/:id"
              element={<ListingDetailsOne />}
            />
            <Route path="/property/:type/:listingtype" element={<PropertyListing />} />
            {/* <Route path="/property/:type" element={<PropertyListing />} /> */}
            <Route path="/sell/:type" element={<PropertyListing />} />
            <Route path="/rent/:type" element={<PropertyListing />} />
            <Route path="/view-all" element={<View />} />
            <Route path="/Distress/:type" element={<Distress />} />
            <Route path="/Planing/:type" element={<Planing />} />
            <Route path="/Modification/:type" element={<Modification />} />
            <Route path="/Management/:type" element={<Management />} />
            <Route path="/Planner/:type" element={<Planner />} />
            <Route path="/sellproperty" element={<Sellproperty />} />
            <Route path="/Property/:type" element={<Property />} />
            <Route path="/Terms/:type" element={<Terms />} />
            <Route path="/Retire/:type" element={<Retire />} />
            <Route path="/Termsandcond" element={<Termsandcond />} />
            <Route path="/Complain" element={<Complain />} />
            <Route path="/job" element={<Job />} />
            {/* <Route path="/AgentCreate" element={<AgentCreate />} /> */}
            <Route path="/SellForm" element={<SellForm />} />
            <Route path="/refundpolicy" element={<Refundpolicy />} />
            <Route path="/privacypolicy" element={<Privacypolicy />} />
            <Route path="/cookiepolicy" element={<Cookiepolicy />} />
            <Route path="/Estate/:type" element={<Estate />} />
            <Route path="/Mortgage" element={<Mortgage />} />
            <Route path="/Invest" element={<Invest />} />
            <Route path="/Loans" element={<Loans />} />
            <Route path="/listing-map" element={<Listing />} />
            <Route path="/blog-news" element={<Bloggrid />} />
            <Route path="/blog" element={<Bloglist />} />
            <Route path="/Think-to-know" element={<Retire />} />
            <Route path="/Own-Property-in-Thailand-Laws" element={<ThailandLaws />} />
            <Route path="*" element={<div>Error 404 Page not found.</div>} />
          </Routes>

          {/* <FloatButton.BackTop
            style={{ marginBottom: "55px", marginRight: "25px" }}
          /> */}

          {showScroll &&
            <button onClick={scrollTop} style={{
              position: 'fixed',
              bottom: '100px',
              right: '50px',
              background: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
              borderRadius: 100,
              width: 35,
              height: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <BiVerticalTop size={30} />
            </button>
          }

        </div>
      </I18nextProvider>
    </LanguageProvider >
  );
};

export default App;
